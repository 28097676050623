import { View } from "../../../View";
import { BlankInput } from "../../BlankInput";
import { ColorPickerInput } from "../../ColorPicker/ColorPicker.Input";
import { DateInput } from "../../DateInput/DateInput";
import { DocumentPickerInput } from "../../DocumentPicker/DocumentPicker.Input";
import { EmailInput } from "../../EmailInput/EmailInput";
import { LangSelectorInput } from "../../LangSelector/LangSelector.Input";
import { NestedInput } from "../../NestedInput/NestedInput";
import { NumberInput } from "../../NumberInput/NumberInput";
import { PhoneInput } from "../../PhoneInput/PhoneInput";
import { SelectMultiInput } from "../../SelectInput/SelectInput.Multi";
import { SelectSingleInput } from "../../SelectInput/SelectInput.Single";
import { TextInput } from "../../TextInput/TextInput";
import { ToggleInput } from "../../ToggleInput/ToggleInput";
import { FormBuilderImport } from "./FormBuilder.Import";
import type {
  CommonInputProps,
  FormBuilderInputProps,
} from "./FormBuilder.types";

const FormBuildSelector = <T,>(props: FormBuilderInputProps<T, any>) => {
  const { config, extensions } = props;
  const formInputProps: Omit<CommonInputProps<any, any>, "config"> = props;

  switch (config.type) {
    case "blank": {
      return <BlankInput {...formInputProps} config={config} />;
    }

    case "color-picker": {
      return <ColorPickerInput {...formInputProps} config={config} />;
    }
    case "file-upload": {
      const extension = props.extensions?.["file-upload"]?.find(
        (ex) => ex.id === config.id
      );
      if (!extension) {
        console.error(`No extension found for 'file-upload' ${config.id}`);
        return <></>;
      }
      return (
        <DocumentPickerInput
          {...formInputProps}
          config={config}
          uploadFile={extension.uploadFile}
        />
      );
    }
    case "custom": {
      const extension = extensions?.["custom"]?.find(
        (ex) => ex.id === config.id
      );

      if (!extension) {
        console.error(`No extension found for 'custom' ${config.id}`);
        return <></>;
      }

      return (
        <extension.component
          config={config}
          readonly={formInputProps.readonly || config.readonly}
          form={formInputProps.form}
          disabled={formInputProps.disabled}
        />
      );
    }
    case "date": {
      return (
        <DateInput
          {...formInputProps}
          config={config}
          calender={config.calender}
        />
      );
    }
    case "email": {
      return <EmailInput {...formInputProps} config={config} />;
    }
    case "form-item": {
      return <NestedInput {...formInputProps} config={config} />;
    }
    case "lang-text": {
      return <LangSelectorInput {...formInputProps} config={config} />;
    }
    case "multi-select": {
      return <SelectMultiInput {...formInputProps} config={config} />;
    }
    case "number": {
      return <NumberInput {...formInputProps} config={config} />;
    }
    case "phone": {
      return <PhoneInput {...formInputProps} config={config} />;
    }
    case "single-select": {
      return <SelectSingleInput {...formInputProps} config={config} />;
    }
    case "text": {
      return <TextInput {...formInputProps} config={config} />;
    }
    case "toggle": {
      return <ToggleInput {...formInputProps} config={config} />;
    }
    default: {
      console.error("Unsupported config item:", config);
      return <></>;
    }
  }
};

export const FormBuilderListItem = <T,>(
  props: FormBuilderInputProps<T, any>
) => {
  return (
    <View
      my="2"
      flex={1}
      style={[!props.config.noFeedback && { minHeight: 94 }, props.style]}
    >
      <FormBuildSelector {...props} />
    </View>
  );
};

FormBuilderImport.FormBuildSelector = FormBuildSelector;

import type {
  FormBuilderInputProps,
  FormBuilderProps,
} from "./FormBuilder.types";

export const FormBuilderImport = {
  FormBuilder: (() => {}) as any as <T>(
    props: FormBuilderProps<T>
  ) => JSX.Element,

  FormBuildSelector: (() => {}) as any as <T>(
    props: FormBuilderInputProps<T, any>
  ) => JSX.Element,
};

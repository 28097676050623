import { translate } from "../../../lang/lang";
import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";

export const EmailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const useEmailForm = (props: FormHookProp<string, "email">) => {
  return useInputForm({
    ...props,
    test: (arg) => EmailRegex.test(arg),
    errorFeedback: translate("app.inputs.invalidEmail"),
  });
};

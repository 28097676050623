import { createContext, useRef } from "react";

import { CalenderManager } from "./CalenderManager";
import type {
  CalenderData,
  CalenderManagerProps,
  CalenderManagerProviderProps,
} from "./CalenderManager.types";

export const CalenderContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calenderManager: new CalenderManager({} as any),
  calenderData: [] as CalenderData,
  calenderOptions: {} as CalenderManagerProps,
});
export const CalenderProvider = ({
  children,
  calenderOptions,
}: CalenderManagerProviderProps) => {
  const calenderManager = useRef(new CalenderManager(calenderOptions)).current;
  return (
    <CalenderContext.Provider
      value={{
        calenderManager,
        calenderData: calenderManager.getCalenderData(),
        calenderOptions,
      }}
    >
      {children}
    </CalenderContext.Provider>
  );
};

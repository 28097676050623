import React, { useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { ColorKeys, useColorFromTheme } from "../../theme";

export type ToggleSize = "small" | "medium" | "large"; // TODO needs confirming

export interface ToggleBaseProps {
  value: boolean;
  color: ColorKeys;
  /**
   * @default medium
   */
  size?: ToggleSize;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  readonly?: boolean;
}

export interface OwnProps {
  style?: ViewStyle;
}

export type ToggleProps = ToggleBaseProps & OwnProps;

const styles = StyleSheet.create<any>({
  wrapper: ({
    color,
    width,
    height,
    borderRadius,
  }: {
    color: string;
    width: number;
    height: number;
    borderRadius: number;
  }) => ({
    width: width,
    height: height,
    borderRadius: borderRadius,
    backgroundColor: color,
    justifyContent: "center",
    alignItems: "flex-start",
  }),
  slider: (size: number) => ({
    height: size,
    width: size,
    borderRadius: size / 2,
    backgroundColor: "white",
  }),
});

const sizes = {
  small: {
    wrapper: {
      width: 26,
      height: 16,
      borderRadius: 67,
    },
    sliderSize: 12,
    sliderPositions: [2, 12],
  },
  medium: {
    wrapper: {
      width: 40,
      height: 24,
      borderRadius: 100,
    },
    sliderSize: 20,
    sliderPositions: [2, 18],
  },
};

export function Toggle(props: ToggleProps) {
  const { value, color, onChange, disabled, size, style, readonly } = props;
  const { wrapper, slider } = styles;
  const activeColor = useColorFromTheme(color);
  const inactiveColor = useColorFromTheme("monochrome-light");
  const disabledColor = useColorFromTheme("monochrome-extraLight");
  const backgroundColor = disabled
    ? disabledColor
    : value
    ? activeColor
    : inactiveColor;
  const sizeProps = size === "small" ? sizes.small : sizes.medium;
  const wrapperProps = sizeProps.wrapper;
  const sliderSize = sizeProps.sliderSize;
  const sliderPositions = sizeProps.sliderPositions;

  const slideValue = useRef(new Animated.Value(value ? 1 : 0)).current;

  useEffect(() => {
    Animated.timing(slideValue, {
      toValue: value ? 1 : 0,
      duration: 150,
      useNativeDriver: true,
      easing: Easing.in(Easing.linear),
    }).start();
  }, [slideValue, value]);

  return (
    <Pressable
      style={style}
      disabled={disabled || readonly}
      onPressIn={() => onChange && onChange(!value)}
    >
      <View
        style={wrapper({
          color: backgroundColor,
          ...wrapperProps,
        })}
      >
        <Animated.View
          style={[
            slider(sliderSize),
            {
              transform: [
                {
                  translateX: slideValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: sliderPositions,
                  }),
                },
              ],
            },
          ]}
        />
      </View>
    </Pressable>
  );
}

import React from "react";
import { Text as DefaultText, TextProps, TextStyle } from "react-native";
import { TranslatableText, translate } from "../../lang/lang";
import {
  ColorKeys,
  getColorFromProvidedTheme,
  Typography,
  useAppTheme,
} from "../../theme";

export type BaseTextProps = TextProps & {
  color?: ColorKeys;
  fontWeight?:
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";
  type: keyof Typography;
  text?: TranslatableText;
  align?: TextStyle["textAlign"];
  bold?: boolean;
  flex?: number;
};

export type AppTextProps = Omit<BaseTextProps, "type"> & {
  type?: keyof Typography;
};

const StrongTag = "<strong>";
const StrongCloseTag = "</strong>";
function transText(text: string, options: Partial<BaseTextProps>) {
  const split1 = text.split(StrongTag);
  const split2 = split1.flatMap((split1Item) => {
    const strongTagIndex = split1Item.indexOf(StrongCloseTag);
    if (strongTagIndex !== -1) {
      const strongSplits = split1Item.split(StrongCloseTag);
      return [
        <DefaultText
          {...options}
          style={[options.style, { fontWeight: "bold" }]}
        >
          {strongSplits[0]}
        </DefaultText>,
        strongSplits[1],
      ];
    }
    return split1Item;
  });
  return split2;
}

const Text = ({
  type,
  style,
  fontWeight,
  color,
  text,
  children,
  align,
  bold,
  flex,
  ...textProps
}: BaseTextProps) => {
  const theme = useAppTheme();
  const textSelective = theme.typography[type || "small"];
  const textStyle = [
    {
      fontWeight: bold ? "800" : fontWeight,
      fontFamily: textSelective.fontFamily,
      letterSpacing: textSelective.letterSpacing,
      lineHeight: textSelective.lineHeight,
      fontSize: textSelective.fontSize,
      color: getColorFromProvidedTheme(color || "black", theme),
      textAlign: align,
      flex,
    },
    style,
  ];
  const content = text
    ? transText(translate(text), { ...textProps, style: textStyle })
    : children;

  return (
    <DefaultText {...textProps} style={textStyle}>
      {content}
    </DefaultText>
  );
};

/**
 @description Big title
 */
Text.Heading1 = (props: AppTextProps) => <Text type="heading1" {...props} />;
/**
  @description Medium title
  */
Text.Heading2 = (props: AppTextProps) => <Text type="heading2" {...props} />;
/**
  @description Small title
  */
Text.Heading3 = (props: AppTextProps) => <Text type="heading3" {...props} />;

Text.SectionTitle = (props: AppTextProps) => (
  <Text type="sectionTitle" {...props} />
);

Text.Body1SemiBold = (props: AppTextProps) => (
  <Text type="body1SemiBold" {...props} />
);

Text.Body1Medium = (props: AppTextProps) => (
  <Text type="body1Medium" {...props} />
);

Text.Body1Regular = (props: AppTextProps) => (
  <Text type="body1Regular" {...props} />
);

Text.Body2Medium = (props: AppTextProps) => (
  <Text type="body2Medium" {...props} />
);

Text.Body2Regular = (props: AppTextProps) => (
  <Text type="body2Regular" {...props} />
);

Text.Small = (props: AppTextProps) => <Text type="small" {...props} />;

export { Text };

import {
  AppTheme,
  ColorCategory,
  ColorKeys,
  getColorFromProvidedTheme,
} from "../../theme";
import { getColorShade } from "../../utils/utils.ui";
import type { ButtonVariant } from "./Button.types";

export const buttonHeight = {
  regular: 48,
  small: 36,
};
export const getButtonBackgroundColor = ({
  theme,
  color,
  variant,
  disabled,
  hovered,
}: {
  theme: AppTheme;
  color: ColorCategory;
  variant: ButtonVariant;
  disabled?: boolean;
  hovered?: boolean;
}) => {
  const disabledColor = theme.palette.primary.base;
  switch (variant) {
    case "contained": {
      const bgKey = hovered
        ? getColorShade(color, "dark")
        : getColorShade(color, "mid");
      const mainColor = getColorFromProvidedTheme(bgKey, theme);
      return disabled ? disabledColor : mainColor;
    }
    case "outline":
    case "text":
    default: {
      const mainColor = hovered
        ? getColorFromProvidedTheme(getColorShade(color, "base"), theme)
        : "transparent";
      return mainColor;
    }
  }
};

export const getBorderColor = ({
  theme,
  color,
  variant,
  disabled,
}: {
  theme: AppTheme;
  color: ColorCategory;
  variant: ButtonVariant;
  disabled?: boolean;
  hovered?: boolean;
}) => {
  const disabledColor = theme.palette.monochrome.extraLight;
  switch (variant) {
    case "outline": {
      const mainColor = getColorFromProvidedTheme(
        getColorShade(color, "light"),
        theme
      );
      return disabled ? disabledColor : mainColor;
    }

    case "contained":
    case "text":
    default: {
      return "transparent";
    }
  }
};

export const getTextColorKey = (
  color: ColorCategory,
  variant: ButtonVariant,
  disabled?: boolean
): ColorKeys => {
  switch (variant) {
    case "text":
    case "outline":
      return disabled ? "monochrome-light" : getColorShade(color, "mid");
    case "contained":
      return disabled ? "monochrome-light" : "white";
  }
};

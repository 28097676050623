import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { createErrorResponse, ErrorResponse, isErrorResponse } from "../error";
import { getKeysOfObject } from "../utils.helper";

import type {
  BaseFetchProps,
  FetchDeviceId,
  FetchLang,
  FetchPushId,
  FetchUserId,
  FetchUserToken,
  FetchUtilProps,
  HeaderKeys,
  PostFetchProps,
} from "./network.types";
export class FetchManager {
  private _getUserToken: FetchUserToken;
  private _getUserId: FetchUserId;
  private _getDeviceId: FetchDeviceId;
  private _getPushId?: FetchPushId;
  private _getLang: FetchLang;

  private _appVersion: string;
  private _getBaseUrl: () => string;
  private _debug?: boolean;
  private _defaultErrorMessage: string;
  constructor(props: FetchUtilProps) {
    this._getUserToken = props.getUserToken;
    this._getUserId = props.getUserId;
    this._getDeviceId = props.getDeviceId;
    this._getPushId = props.getPushId;
    this._getLang = props.getLang;

    this._appVersion = props.appVersion;
    this._getBaseUrl = props.baseUrl;
    this._debug = props.debug;
    this._defaultErrorMessage = props.defaultErrorMessage;
  }

  getHeaders = async (): Promise<HeaderKeys> => {
    const userToken = await this._getUserToken();
    const userId = await this._getUserId();
    const deviceId = await this._getDeviceId();
    const pushId = (await this._getPushId?.()) || "";
    const lang = await this._getLang();
    return {
      lang: lang,
      version: this._appVersion,
      "device-type": pushId,
      "device-id": deviceId,
      "push-id": pushId,
      "user-id": userId,
      "auth-token": userToken,
      "other-token": new Date().valueOf() + "",
    };
  };

  redux = {
    rawBaseQuery: fetchBaseQuery({
      baseUrl: "",
      prepareHeaders: async (headers) => {
        const additionalHeaders = await this.getHeaders();
        getKeysOfObject(additionalHeaders).forEach((headerKey) => {
          headers.set(headerKey, additionalHeaders[headerKey]);
        });

        // headers.set("Access-Control-Allow-Origin", "*");
        // headers.set("mode", "no-cors");

        return headers;
      },
    }),
    adjustedFetchArgs: (args: FetchArgs | string) => {
      const urlEnd = typeof args === "string" ? args : args.url;
      // construct a dynamically generated portion of the url
      const adjustedUrl = `${this._getBaseUrl()}${urlEnd}`;
      const adjustedArgs =
        typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };

      return adjustedArgs;
    },
  };

  axiosBaseQuery: AxiosBaseQuery = async ({ url, method, data, params }) => {
    return this.request({
      endPoint: url,
      method: method as "POST" | "GET",
      body: data,
      params,
    })
      .then((data) => {
        if (isErrorResponse(data)) {
          return {
            error: {
              status: 400,
              data: data,
            },
          };
        }
        return { data };
      })
      .catch((err) => {
        const errorData = createErrorResponse({
          error: err,
          defaultMessage: this._defaultErrorMessage,
        });

        return {
          error: {
            status: 400,
            data: errorData,
          },
        };
      });
  };

  baseQuery: BaseQueryFn<FetchArgs | string, unknown, FetchBaseQueryError> =
    async (args, api, extraOptions) => {
      // provide the amended url and other params to the raw base query
      return this.redux.rawBaseQuery(
        this.redux.adjustedFetchArgs(args),
        api,
        extraOptions
      );
    };

  request = async <T>({
    endPoint,
    params = "",
    signal,
    timeout,
    errorMessage,
    method,
    body,
    headers,
  }: BaseFetchProps & { body?: any; method: "POST" | "GET" }): Promise<
    T | ErrorResponse
  > => {
    const url = this._getBaseUrl() + endPoint + params;
    try {
      const additionalHeaders = await this.getHeaders();
      const response = await axios({
        headers: {
          ...additionalHeaders,
          ...headers,
        },
        method,
        url,
        cancelToken: signal && signal.token,
        timeout,
        data: body,
      });

      if (this._debug) {
        console.info(
          `Fetch response: ${method}`,
          url,
          body,
          typeof response.data,
          response.data
        );
      }

      return response.data as T;
    } catch (error) {
      if (this._debug) {
        console.info(`Fetch Error: ${method}`, url, error);
      }
      return createErrorResponse({
        error,
        defaultMessage: errorMessage || this._defaultErrorMessage,
      });
    }
  };

  get = async <T>(props: BaseFetchProps): Promise<T | ErrorResponse> => {
    return this.request<T>({ ...props, method: "GET" });
  };

  post = async <T>(props: PostFetchProps): Promise<T | ErrorResponse> => {
    return this.request<T>({ ...props, method: "POST" });
  };

  stream = async <T>(
    props: BaseFetchProps & {
      subscribeToStream: (data: T | ErrorResponse) => () => void;
    }
  ): Promise<void> => {
    const response = await this.request<AxiosResponse<any, any>["data"]>({
      ...props,
      method: "GET",
      responseType: "stream",
    });

    if (isErrorResponse(response)) {
      props.subscribeToStream(response);
      return;
    }
    response.on("data", (data: T) => {
      props.subscribeToStream(data);
    });
  };
}

export type AxiosBaseQuery = BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
  },
  unknown,
  unknown
>;

import { translate } from "../../lang/lang";
import { VStack } from "../Stack";
import { Text } from "../Text";
import { ModalBody } from "./Modal.Body";
import { ModalFooter } from "./Modal.Footer";
import { ModalHeader } from "./Modal.Header";
import { useModal } from "./Modal.Provider";

export const useModalVerify = () => {
  const ModalKey = new Date().valueOf() + "verify";
  const { openModal, closeModal } = useModal();

  const refCurrent = {
    resolve: (() => {}) as (value: boolean | PromiseLike<boolean>) => void,
  };

  return {
    verifyAction: async (props?: {
      title?: string;
      description?: string;
    }): Promise<boolean> => {
      return new Promise((res) => {
        refCurrent.resolve = res;
        openModal(
          <VStack>
            <ModalHeader
              title={props?.title ?? translate("app.modal.verify")}
              divider
              onPress={() => {
                refCurrent.resolve(false);
                closeModal(ModalKey);
              }}
            />
            <ModalBody
              style={{ minHeight: 100 }}
              justify="center"
              align="center"
            >
              <Text.Body1Regular align="center">
                {props?.description ?? translate("app.modal.areYouSure")}
              </Text.Body1Regular>
            </ModalBody>
            <ModalFooter
              divider
              onConfirm={() => {
                refCurrent.resolve(true);
                closeModal(ModalKey);
              }}
              onCancel={() => {
                refCurrent.resolve(false);
                closeModal(ModalKey);
              }}
            />
          </VStack>,
          { key: ModalKey, type: "pop-up" }
        );
      });
    },
    cancelVerify: () => {
      refCurrent.resolve(false);
      closeModal(ModalKey);
    },
  };
};

import { Pressable, StyleSheet } from "react-native";
import { AppTheme, useAppTheme } from "../../theme";
import type { ButtonInteractionState, InputButtonProps } from "./Button.types";

const styles = StyleSheet.create<any>({
  focusWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.primary.mid,
    backgroundColor: theme.palette.primary.base,
  }),
  // errorWrapper: (theme: AppTheme) => ({
  //   borderColor: theme.palette.error.dark,
  // }),
  disabledWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.monochrome.extraLight,
    backgroundColor: theme.palette.monochrome.base,
  }),
});

export const InputButton = (props: InputButtonProps) => {
  const theme = useAppTheme();
  return (
    <Pressable
      style={({ pressed, hovered }: ButtonInteractionState) => {
        return [
          theme.components.buttonInput,
          hovered && !props.readonly && styles.focusWrapper(theme),
          { opacity: pressed ? 0.8 : 1 },
          props.style,
          props.disabled && styles.disabledWrapper(theme),
        ];
      }}
      accessibilityRole="button"
      disabled={props.disabled || props.readonly}
      onPress={props.onPress}
    >
      {props.children}
    </Pressable>
  );
};

import { BackHandler } from "react-native";
import { isAndroid, isWeb } from "./utils.helper";

export type HardwarePressHandler = {
  code?: number | string;
  handler: () => void;
  mode: "stack" | "parallel";
  key: string;
  oneTimeUse: boolean;
};

class BackHardwareListener {
  private _listeners: HardwarePressHandler[] = [];
  private _onBackPress = (code?: string): boolean => {
    [...this._listeners].reverse().forEach((listener, index) => {
      const correctCode = code === listener.code || !isWeb;
      if (index === 0 && correctCode) {
        listener.handler();
        if (listener.oneTimeUse) {
          this.unsubscribe(listener.key);
        }
        return;
      }
      if (listener.mode === "parallel" && index > 0 && correctCode) {
        listener.handler();
        if (listener.oneTimeUse) {
          this.unsubscribe(listener.key);
        }
        return;
      }
    });

    return true;
  };

  constructor() {
    if (isWeb) {
      document.addEventListener("keydown", (ev) => {
        if (this._listeners.length === 0) {
          return;
        }
        this._onBackPress(String(ev.key));
      });
    } else if (isAndroid) {
      BackHandler.addEventListener("hardwareBackPress", () => {
        if (this._listeners.length === 0) {
          return false;
        }
        return this._onBackPress();
      });
    }
  }

  unsubscribe = (key: string) => {
    this._listeners = this._listeners.filter(
      (listener) => listener.key !== key
    );
  };

  subscribe = (handler: HardwarePressHandler) => {
    if (isWeb) {
      handler.code = handler.code || "Escape"; // esc key === Escape
    }

    this.unsubscribe(handler.key);
    this._listeners.push(handler);
  };
}

export const backHardwareListener = new BackHardwareListener();

import { isWeb } from "./utils.helper";

if (isWeb) {
  // Generate required css
  const customStyles = `

#root {
  overflow: hidden;
}

input,
textarea {
  outline: none;
}

[role=button] {
  outline: none;
}

div {
    user-select: text;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
-webkit-text-fill-color: 'none';
-webkit-box-shadow: 0 0 0 30px white inset !important;
transition: background-color 5000s ease-in-out 0s;
}

.gm-style > div:nth-child(2) {
cursor: auto !important;
}

.file-picker {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


::-webkit-scrollbar {
width: 5px;
height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #c0c0c0; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #888; 
}

`;

  // Create stylesheet
  const style = document.createElement("style");
  style.type = "text/css";
  // @ts-ignore
  if (style.styleSheet) {
    // @ts-ignore
    style.styleSheet.cssText = customStyles;
  } else {
    style.appendChild(document.createTextNode(customStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);

  const input = document.createElement("input");
  input.type = "file";
  input.tabIndex = -1;
  input.className = "file-picker";
  input.id = "file-picker";
  document.body.appendChild(input);
}

import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";

type FormItemValue<T> = T extends "item" ? { [key: string]: any } : any[];

export const useNestedInputForm = <T extends "list" | "item">(
  props: FormHookProp<FormItemValue<T>, "form-item"> & {
    isFormValid?: React.MutableRefObject<() => boolean>;
  }
) => {
  return useInputForm(
    props
      ? {
          ...props,
          test: () => {
            if (props.isFormValid) {
              props.isFormValid.current();
            }
            return true;
          },
          getValue: (cv) => {
            if (props.config.allowEmpty) {
              return cv;
            } else {
              const stringValue = JSON.stringify(cv);
              if (stringValue === "[]" || stringValue === "{}") {
                return undefined;
              }
              return cv;
            }
          },
        }
      : props
  );
};

import { dayjs } from "../../utils/utils.date";
import type {
  CalenderMarkedDateOptions,
  CalenderMarkedDates,
  GetDaysInAMonthListProps,
  WeekItem,
} from "./CalenderManager.types";

export const getMarkedDates = (
  newDates: dayjs.Dayjs[],
  format: string,
  viewableMonths: dayjs.Dayjs[]
): CalenderMarkedDates => {
  const newMarkedDates: CalenderMarkedDates = {};
  const datesInUnix = newDates.map((newDate) => newDate.valueOf());
  const earliestDate = dayjs(Math.min(...datesInUnix));
  const latestDate = dayjs(Math.max(...datesInUnix));
  const daysBetween = latestDate.diff(earliestDate, "days");

  const viewableDates = viewableMonths.reduce((memo, next) => {
    return [...memo, ...getCurrentMonthDates(next)];
  }, [] as dayjs.Dayjs[]);

  for (let index = 0; index < viewableDates.length; index++) {
    const viewableDate = viewableDates[index];
    const newDateFormat = viewableDate.format(format);
    newMarkedDates[newDateFormat] = {
      isOnViewableDate: true,
    };
  }

  // Run marked dates first
  for (let index = 0; index < newDates.length; index++) {
    const newDate = newDates[index];
    const newDateFormat = newDate.format(format);
    const exists = !!newMarkedDates[newDateFormat];

    if (exists) {
      newMarkedDates[newDateFormat] = {
        ...newMarkedDates[newDateFormat],
        selectedIndexes: [
          ...(newMarkedDates[newDateFormat].selectedIndexes || []),
          index,
        ],
        currentIndex: index,
        startDate: !!newDates[index + 1],
        endDate: !!newDates[index - 1],
        // isOnViewableMonth,
      };
    } else {
      newMarkedDates[newDateFormat] = {
        ...newMarkedDates[newDateFormat],
        selectedIndexes: [index],
        currentIndex: index,
        startDate: !!newDates[index + 1],
        endDate: !!newDates[index - 1],
        // isOnViewableMonth,
      };
    }
  }

  for (let index = 0; index < daysBetween; index++) {
    const currentDate = earliestDate.add(index, "day");
    const formattedCurrentDate = currentDate.format(format);
    if (newMarkedDates[formattedCurrentDate]?.currentIndex === undefined) {
      newMarkedDates[formattedCurrentDate] = {
        isOnViewableDate: true,
        between: true,
      };
    }
  }

  return newMarkedDates;
};

export const areMarkedDatesTheSame = (
  prevOption: CalenderMarkedDateOptions | undefined = {},
  nextOption: CalenderMarkedDateOptions | undefined = {}
) => {
  return (
    prevOption.between === nextOption.between &&
    prevOption.disableTouchEvent === nextOption.between &&
    prevOption.disabled === nextOption.disabled &&
    prevOption.endDate === nextOption.endDate &&
    prevOption.currentIndex === nextOption.currentIndex &&
    prevOption.selectedIndexes?.length === nextOption.selectedIndexes?.length &&
    prevOption.startDate === nextOption.startDate &&
    prevOption.isOnViewableDate === nextOption.isOnViewableDate
  );
};

export const convertToArrayChunks = <T>(array: T[], chunkSize: number) => {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] as T[]; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, [] as T[][]);
};

const getCurrentMonthDates = (date: dayjs.Dayjs) =>
  new Array(date.daysInMonth())
    .fill(null)
    .map((_, i) => date.startOf("month").add(i, "days"));

export const getDaysInAMonthList = (props: GetDaysInAMonthListProps) => {
  const daysInMonthList: WeekItem = [];
  const daysInMonth = props.monthDate.daysInMonth();
  const firstDayOfTheWeek = props.monthDate.set("date", 1).day(); // 1st (which day of the week) e.g. 0 (sunday) or 2 (Tuesday)
  const lastDayOfTheWeek = props.monthDate.set("date", daysInMonth).day(); // 1st (which day of the week) e.g. 6 (saturday) or 5 (Friday)
  const firstEmptyFillers = firstDayOfTheWeek;
  const lastEmptyFillers = 6 - lastDayOfTheWeek;

  for (let rIndex = firstEmptyFillers; rIndex > 0; rIndex--) {
    daysInMonthList.push({
      dateString: undefined,
      dateNumber: undefined,
      dateMonth: undefined,
    });
  }

  const year = props.monthDate.year();
  const dateMonth = props.monthDate.month();
  const month = dateMonth + 1;

  for (let index = 0; index < daysInMonth; index++) {
    const dateNumber = index + 1;
    const dateNumberWithZero =
      dateNumber < 10 ? `0${dateNumber}` : `${dateNumber}`;
    const monthNumberWithZero = month < 10 ? `0${month}` : `${month}`;

    daysInMonthList.push({
      dateString: dayjs(
        `${dateNumberWithZero}-${monthNumberWithZero}-${year}`,
        "DD-MM-YYYY"
      ).format(props.dateFormat),
      dateNumber,
      dateMonth: dateMonth,
    });
  }

  for (let index = 0; index < lastEmptyFillers; index++) {
    daysInMonthList.push({
      dateString: undefined,
      dateNumber: undefined,
      dateMonth: undefined,
    });
  }
  return daysInMonthList;
};

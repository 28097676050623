import React from "react";
import { ActivityIndicator, ActivityIndicatorProps } from "react-native";
import { ColorKeys, useColorFromTheme } from "../../theme";

export interface LoadingIndicatorProps extends ActivityIndicatorProps {
  color?: ColorKeys;
}

export const LoadingIndicator = ({
  color = "primary-mid",
  ...props
}: LoadingIndicatorProps) => {
  const themeColor = useColorFromTheme(color);
  return <ActivityIndicator {...props} color={themeColor} />;
};

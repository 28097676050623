import { useEffect, useRef, useState } from "react";
import { isWeb } from "../../../utils/utils.helper";
import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";
import { DocumentPickerValue } from "./DocumentPicker.types";
import { extractFileFromEvent } from "./DocumentPicker.utils";

export const useDocumentPickerForm = (
  props: FormHookProp<DocumentPickerValue, "file-upload">
) => {
  return useInputForm(props);
};

export const useFileDragUploader = ({
  id,
  files,
  addFile,
}: {
  id: string;
  files: DocumentPickerValue;
  addFile: (_: FormData) => void;
}) => {
  const [draggedOver, setDragOver] = useState(false);

  const ref = useRef<HTMLElement | undefined | null>();

  const unsubscribeFromDragAndDrop = () => {
    if (ref.current) {
      ref.current.ondragover =
        ref.current.ondragenter =
        ref.current.ondragleave =
        ref.current.ondragover =
        ref.current.ondrop =
          () => {};
    }
  };

  const subscribeToDragAndDrop = () => {
    unsubscribeFromDragAndDrop();
    ref.current = document.getElementById(id);
    if (ref.current) {
      ref.current.ondragover = function (ev) {
        ev.preventDefault();
      };
      ref.current.ondragenter = (ev) => {
        setDragOver(true);
        ev.preventDefault();
      };
      ref.current.ondragleave = (ev) => {
        setDragOver(false);
        ev.preventDefault();
      };
      ref.current.ondrop = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        const formData = extractFileFromEvent(ev.dataTransfer?.files, files);
        if (formData) {
          addFile(formData);
        }
        setDragOver(false);
      };
    }
  };

  useEffect(() => {
    if (isWeb) {
      subscribeToDragAndDrop();
      return () => unsubscribeFromDragAndDrop();
    }
  }, [files]);

  return {
    draggedOver,
  };
};

import React, { useCallback, useState } from "react";
import { FlatList, ListRenderItem, Pressable } from "react-native";
import { translate } from "../../lang/lang";
import { inputHeight, useAppTheme } from "../../theme";
import { isWeb } from "../../utils/utils.helper";
import type { ButtonInteractionState } from "../Button/Button.types";
import { Divider } from "../Divider/Divider";
import { SearchBar } from "../Search/Search";
import { HStack, VStack } from "../Stack";
import { Text } from "../Text";
import { View } from "../View";

export const DropdownMenuList = <T, X>({
  data,
  value,
  isSearchable,
  isClearable,
  onPress,
  renderItem,
}: {
  data: T[];
  value: X;
  renderItem: (item: T, index: number) => React.ReactNode;
  onPress: (item: T) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
}) => {
  const [list, setList] = useState(data);
  const { palette, components } = useAppTheme();
  const renderItemCallback: ListRenderItem<T> = useCallback(
    ({ item, index }) => {
      return (
        <Pressable
          key={index}
          style={({ hovered }: ButtonInteractionState) => {
            return [hovered && { backgroundColor: palette.primary.base }];
          }}
          onPress={() => onPress(item)}
        >
          <HStack
            style={{ height: components.buttonInput?.height || inputHeight }}
            px="5"
          >
            {renderItem(item, index)}
          </HStack>
          {data.length > index + 1 ? <Divider hairline /> : null}
        </Pressable>
      );
    },
    [data, value]
  );
  const keyExtractor = useCallback((_: T, index: number) => String(index), []);

  const ListView = (
    <FlatList
      renderItem={renderItemCallback}
      keyExtractor={keyExtractor}
      data={list}
    />
  );

  return (
    <VStack style={[{ flex: 1 }]}>
      {isSearchable ? (
        <>
          <SearchBar
            placeholder={translate("app.inputs.search")}
            data={data}
            onSearch={(filteredList) => {
              setList(filteredList);
            }}
            isClearable={isClearable}
          />
          <Divider />
        </>
      ) : null}

      {list.length ? (
        isWeb ? (
          <View
            style={[
              isWeb && {
                // @ts-expect-error
                overflowY: "auto",
                flexGrow: 1,
                flexShrink: 1,
                flexDirection: "column",
              },
            ]}
          >
            {ListView}
          </View>
        ) : (
          ListView
        )
      ) : (
        <View py="3" align="center" justify="center">
          <Text.Small align="center">
            {translate("app.inputs.noData")}
          </Text.Small>
        </View>
      )}
    </VStack>
  );
};

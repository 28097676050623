import { useCallback } from "react";
import {
  FlatList as FlatListRN,
  FlatListProps,
  ListRenderItem,
} from "react-native";
import { useAppTheme } from "../../theme";
import { View } from "../View";

export interface ListProps<T>
  extends Omit<FlatListProps<T>, "data" | "renderItem" | "keyExtractor"> {
  data: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  onPressItem?: (item: T, index: number) => void;
}

export const FlatList = <T extends { id: string }>(props: ListProps<T>) => {
  const { spacing } = useAppTheme();
  const renderItem: ListRenderItem<T> = useCallback(({ item, index }) => {
    return props.renderItem(item, index);
  }, []);

  const keyExtractor: (item: T, index: number) => string = useCallback(
    (item) => {
      return item.id;
    },
    []
  );

  return (
    <FlatListRN
      {...props}
      contentContainerStyle={[{ flexGrow: 1 }, props.contentContainerStyle]}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={
        props.ItemSeparatorComponent ||
        (() => <View style={{ height: spacing[3], width: "100%" }} />)
      }
    />
  );
};

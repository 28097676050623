import { useAppTheme } from "../../theme";
import { View } from "../View";
import { ModalBackdrop } from "./Modal.Backdrop";
import { modalStyles } from "./Modal.styles";
import type { ModalProps } from "./Modal.types";

export const ActionSheetModal = (props: ModalProps) => {
  const modalActionSheetStyle = useAppTheme().components.modalActionSheet;
  return (
    <ModalBackdrop
      {...props}
      type="action-sheet"
      modalStyle={[
        props.modalStyle,
        modalStyles.actionSheet,
        modalActionSheetStyle,
      ]}
    />
  );
};

export const ActionSheetFullModal = (props: ModalProps) => {
  const modalActionSheetStyle = useAppTheme().components.modalFullActionSheet;
  return (
    <View style={modalStyles.fullActionSheetWrapper}>
      <View
        {...props}
        style={[props.modalStyle, modalActionSheetStyle]}
        flex={1}
      />
    </View>
  );
};

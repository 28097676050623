import { useEffect, useRef } from "react";
import { Animated, Easing, Pressable, StyleSheet } from "react-native";
import type { ChildrenProp, ViewStyle } from "../../types/components";
import { View } from "../View";
import { getScreenHeight } from "../../utils/utils.ui";
import { useModal } from "./Modal.Provider";
import { modalStyles } from "./Modal.styles";
import { useColorFromTheme } from "../../theme";

export interface ModalBackdropProps {
  modalId: string;
  onClose: () => void;
  type: "pop-up" | "action-sheet";
  backdropStyle?: ViewStyle;
  modalStyle?: ViewStyle;
}

export const ModalBackdrop = ({
  children,
  modalId,
  onClose,
  type,
  backdropStyle,
  modalStyle,
}: ChildrenProp & ModalBackdropProps) => {
  const opacity = useRef(new Animated.Value(0)).current;
  const canPress = useRef(false);
  const { closeModal, modals } = useModal();
  const backdropColor = useColorFromTheme("backdrop");
  useEffect(() => {
    opacity.setValue(0);
    Animated.timing(opacity, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
      easing: Easing.in(Easing.linear),
    }).start(() => {
      window.setTimeout(() => {
        canPress.current = true;
      }, 20);
    });
  }, [opacity]);

  const isTopModal = modals[modals.length - 1]?.key === modalId;

  return (
    <View
      style={[
        type === "pop-up" && modalStyles.popupWrapper,
        type === "action-sheet" && modalStyles.actionSheetWrapper,
        // type === "dropdown" && modalStyles.popupWrapper,
        backdropStyle,
      ]}
    >
      <Pressable
        accessibilityRole="button"
        style={modalStyles.pressable}
        onPress={() => {
          if (canPress.current) {
            closeModal(modalId);
            onClose?.();
          }
        }}
      >
        <Animated.View
          style={[
            {
              ...StyleSheet.absoluteFillObject,
              opacity: opacity.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.6],
              }),
              backgroundColor: isTopModal ? backdropColor : undefined,
            },
          ]}
        />
      </Pressable>
      {type === "pop-up" ? (
        <View style={modalStyle}>{children}</View>
      ) : (
        <Animated.View
          style={[
            {
              transform: [
                {
                  translateY: opacity.interpolate({
                    inputRange: [0, 1],
                    outputRange: [getScreenHeight(), 0],
                  }),
                },
              ],
            },
            modalStyle,
          ]}
        >
          {children}
        </Animated.View>
      )}
    </View>
  );
};

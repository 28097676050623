import { ConfigForData, FormGroup } from "@droplet_tech/core-common-types";
import { translate } from "../../../lang/lang";

export const getLabelAndValueFromConfig = <T>(
  config: ConfigForData,
  path: string,
  value: T | undefined
): {
  config: FormGroup | undefined;
  value: T | undefined;
} => {
  let foundConfig: FormGroup | undefined = undefined;
  let foundValue: string = "";
  const splitPaths = path.split(".");

  loop1: for (const c of config) {
    if (c.type === "separator") {
      continue;
    }
    for (const configItem of c.items) {
      if (configItem.id === path) {
        foundConfig = configItem;
        if (Array.isArray(value?.[configItem.id])) {
          foundValue = translate("app.inputs.items", {
            count: value?.[configItem.id].length,
          });
        } else {
          foundValue = value?.[configItem.id];
        }

        break loop1;
      }

      if (
        splitPaths.length > 0 &&
        configItem.type === "form-item" &&
        configItem.id === splitPaths[0]
      ) {
        const foundNestedConfig = getLabelAndValueFromConfig(
          configItem.config,
          splitPaths[1],
          value?.[configItem.id]
        );

        if (Array.isArray(value?.[configItem.id])) {
          foundValue = translate("app.inputs.items", {
            count: value?.[configItem.id].length,
          });
          foundConfig = foundNestedConfig.config;
          break loop1;
        }

        if (foundNestedConfig.config) {
          foundConfig = foundNestedConfig.config;
          foundValue = foundNestedConfig.value;
          break loop1;
        }
      }
    }
  }
  return { config: foundConfig, value: foundValue as any };
};

export const nestedFormSubmit = <T>(
  value: T,
  config: Extract<FormGroup, { type: "form-item" }>
) => {
  if (value === undefined || value === null) {
    return value;
  }

  if (Array.isArray(value)) {
    if (value.length) {
      return value;
    }

    return config.allowEmpty ? [] : undefined;
  }

  if (typeof value === "object") {
    const stringedJson = JSON.stringify(value);
    if (stringedJson !== "{}") {
      return JSON.parse(stringedJson);
    }

    return config.allowEmpty ? {} : undefined;
  }

  return value || (config.allowEmpty ? value : undefined);
};

// export const getInitialNestedValue = (config: NestedInputProps["config"]) => {
//   if (config.initialValue) {
//     return config.initialValue;
//   }

//   const foobar = {};

//   for (const item of config.config) {

//   }

//   return config.initialValue ||
// }

import { isErrorResponse } from "../../../utils/error";
import type { CommonInputProps } from "../Form/FormBuilder/FormBuilder.types";
import { FormInputContainer } from "../FormInputs/FormInput.Container";
import { DocumentPicker } from "./DocumentPicker";
import { useDocumentPickerForm } from "./DocumentPicker.hook";
import { DocumentPickerValue, UploadFileFunc } from "./DocumentPicker.types";
import { getFileFromFormData } from "./DocumentPicker.utils";

export interface DocumentPickerInputProps
  extends CommonInputProps<DocumentPickerValue, "file-upload"> {
  uploadFile: UploadFileFunc;
}

export const DocumentPickerInput = ({
  uploadFile,
  ...formInputProps
}: DocumentPickerInputProps) => {
  const {
    value,
    setValue,
    feedback,
    error,
    allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
  } = useDocumentPickerForm(formInputProps);

  return (
    <FormInputContainer
      disabled={disabled}
      error={error}
      focused={false}
      feedback={feedback}
      label={label}
      optional={optional}
      readonly={readonly}
      noFeedback={noFeedback}
    >
      <DocumentPicker
        files={value}
        onRemoveFile={(file) => {
          const files = value?.filter((v) => v.name !== file.name);
          setValue(allowEmpty && files?.length === 0 ? [] : files);
        }}
        error={error}
        uploadFile={async (file) => {
          const response = await uploadFile(file);
          if (isErrorResponse(response)) {
            return response;
          }

          const name = getFileFromFormData(file)?.name;
          if (name) {
            setValue([...(value || []), { id: response.token, name }]);
          }

          return response;
        }}
      />
    </FormInputContainer>
  );
};

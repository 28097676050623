import { StyleSheet } from "react-native";
import { AppViewProps, View } from "../View";

export type DiverProps = AppViewProps & {
  hairline?: boolean;
  thickness?: number;
  vertical?: boolean;
};

export const Divider = (props: DiverProps) => {
  const thickness = props.hairline
    ? StyleSheet.hairlineWidth
    : props.thickness ?? 1;

  return (
    <View
      bg="monochrome-extraLight"
      {...props}
      style={[
        props.vertical
          ? {
              width: thickness,
              height: "100%",
            }
          : {
              height: thickness,
              width: "100%",
            },
        props.style,
      ]}
    />
  );
};

export const Separator = (props: DiverProps) => {
  return (
    <Divider
      {...props}
      bg="primary-base"
      br={5}
      thickness={props.thickness ?? 15}
    />
  );
};

import { SelectedValueItem } from "@droplet_tech/core-common-types";
import { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { translate } from "../../../lang/lang";
import { Button } from "../../Button/Button";
import { IconButton } from "../../Button/Button.Icon";
import { DropdownMenuList } from "../../Dropdown/Dropdown.List";
import { ModalBody, ModalFooter } from "../../Modal";
import { ModalHeader, ModalHeaderContainer } from "../../Modal/Modal.Header";
import { useModal } from "../../Modal/Modal.Provider";
import { HStack, VStack } from "../../Stack";
import { Text } from "../../Text";
import { View } from "../../View";
import { BaseInputWrapper } from "../BaseInputs/BaseInput.Wrapper";
import { FormInput, FormInputProps } from "../FormInputs/FormInput";
import { LangTextInputValue } from "./LangSelector.hook";

export interface LangSelectorProps
  extends Omit<FormInputProps, "value" | "initialValue"> {
  options: SelectedValueItem[];
  value?: LangTextInputValue;
  onSubmit: (value: LangTextInputValue | undefined) => void;

  label?: string;
  readonly?: boolean;
  disabled?: boolean;
}

export const LangTextSelector = ({
  value,
  options,
  onSubmit,
  error,
  disabled,
}: LangSelectorProps) => {
  const { openModal, closeModal } = useModal();
  const modalKey = "LangTextSelector";
  const defaultEn = value
    ? value.en ||
      Object.keys(value)
        .map((v) => value[v])
        .filter((v) => !!v)[0]
    : "";
  const locales = value ? Object.keys(value) : [];
  const previewLang =
    locales.length > 3
      ? locales.slice(0, 3).join(", ") + "..."
      : locales.join(",");

  return (
    <Pressable
      disabled={disabled}
      onPress={() => {
        openModal(
          <LangTextSelectorController
            options={options}
            initialValue={value}
            onSubmit={(newValue) => {
              onSubmit(newValue);
              closeModal(modalKey);
            }}
            onClose={() => {
              closeModal(modalKey);
            }}
          />,
          { key: modalKey }
        );
      }}
    >
      <BaseInputWrapper
        iconEnd={defaultEn ? "edit" : "plus-square"}
        editable
        error={error}
        disabled={disabled}
      >
        <HStack space="3" flex={1}>
          <Text.Body2Regular
            style={{ flex: 1 }}
            color={defaultEn ? "black" : "monochrome-light"}
          >
            {defaultEn ||
              (locales.length
                ? translate("app.inputs.empty")
                : translate("app.inputs.startByAddingALocale"))}
          </Text.Body2Regular>
          {previewLang.length ? (
            <Text.Small color="monochrome-light">[{previewLang}]</Text.Small>
          ) : null}
        </HStack>
      </BaseInputWrapper>
    </Pressable>
  );
};

const LangTextSelectorController = ({
  options,
  initialValue,
  onSubmit,
  onClose,
}: {
  options: SelectedValueItem[];
  initialValue?: LangTextInputValue;
  onSubmit: (value: LangTextInputValue | undefined) => void;
  onClose: () => void;
}) => {
  const { openModal, closeModal } = useModal();

  const openLocaleAdder = () => {
    openModal(
      <VStack>
        <ModalHeader
          title={translate("app.inputs.addLocale")}
          onPress={() => closeModal(modalKey)}
          divider
        />
        <ModalBody>
          <DropdownMenuList
            onPress={(item) => {
              setNewValue({ ...newValue, [item.value]: "" });
              closeModal(modalKey);
            }}
            value={newValue}
            data={otherLocales}
            renderItem={(item) => {
              return (
                <Text.Body2Regular>
                  {item.label || item.value}
                </Text.Body2Regular>
              );
            }}
          />
        </ModalBody>
      </VStack>,
      { key: modalKey }
    );
  };

  useEffect(() => {
    if (!initialValue) {
      openLocaleAdder();
    }
  }, []);

  const [stateValue, setNewValue] = useState<LangTextInputValue | undefined>(
    initialValue
  );
  const modalKey = "LangTextSelectorController";
  const newValue = stateValue || {};
  const otherLocales = options.filter(
    (option) => typeof newValue[option.value] !== "string"
  );
  const inputLocales = Object.keys(newValue);
  return (
    <VStack space="3">
      <ModalHeaderContainer>
        <Text.Body1Medium
          text="app.inputs.editAcceptedLocale"
          style={{ flex: 1 }}
        />
        <IconButton
          disabled={otherLocales.length === 0}
          icon="plus"
          color="monochrome"
          variant="plain"
          onPress={() => {
            openLocaleAdder();
          }}
        />
      </ModalHeaderContainer>

      <ModalBody>
        <VStack space="3" style={{ minHeight: 69 }}>
          {inputLocales.length ? (
            inputLocales.map((inputLocale) => {
              return (
                <HStack space="1" flex={1} key={inputLocale}>
                  <View flex={1}>
                    <FormInput
                      noFeedback
                      value={newValue[inputLocale]}
                      onChangeText={(txt) => {
                        setNewValue({ ...newValue, [inputLocale]: txt });
                      }}
                      label={
                        options.find((option) => option.value === inputLocale)
                          ?.label || inputLocale
                      }
                    />
                  </View>
                  <VStack>
                    <Text.Body2Regular> </Text.Body2Regular>
                    <IconButton
                      icon="close"
                      variant="plain"
                      color="monochrome"
                      onPress={() => {
                        const newLangText: LangTextInputValue = JSON.parse(
                          JSON.stringify({
                            ...newValue,
                            [inputLocale]: undefined,
                          })
                        );

                        setNewValue(
                          Object.keys(newLangText).length === 0
                            ? undefined
                            : newLangText
                        );
                      }}
                    />
                  </VStack>
                </HStack>
              );
            })
          ) : (
            <View flex={1} align="center" justify="center">
              <Text.Body2Regular
                align="center"
                text="app.inputs.startByAddingALocale"
                color="monochrome-light"
              >
                {" "}
              </Text.Body2Regular>
            </View>
          )}
        </VStack>
      </ModalBody>

      <ModalFooter>
        <HStack flex={1} space="3" justify="flex-end">
          <Button
            text="app.inputs.cancel"
            variant="text"
            fitToContent
            onPress={onClose}
          />
          <Button
            text="app.inputs.save"
            fitToContent
            onPress={() => {
              onSubmit(stateValue);
            }}
          />
        </HStack>
      </ModalFooter>
    </VStack>
  );
};

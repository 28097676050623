import React from "react";
import {
  FlexStyle,
  View as DefaultView,
  ScrollView as DefaultScrollView,
  ViewProps,
  StyleSheet,
  ScrollViewProps,
} from "react-native";
import {
  AppTheme,
  ColorKeys,
  getColorFromProvidedTheme,
  Spacing,
  useAppTheme,
} from "../../theme";

export interface BaseViewProps {
  mx?: keyof Spacing;
  my?: keyof Spacing;
  mt?: keyof Spacing;
  ml?: keyof Spacing;
  mr?: keyof Spacing;
  mb?: keyof Spacing;
  m?: keyof Spacing;
  px?: keyof Spacing;
  py?: keyof Spacing;
  pt?: keyof Spacing;
  pb?: keyof Spacing;
  pr?: keyof Spacing;
  pl?: keyof Spacing;
  p?: keyof Spacing;
  b?: number;
  bc?: ColorKeys;
  br?: number;

  w?: number | string;
  h?: number | string;

  flex?: number;
  bg?: ColorKeys;
  row?: boolean;
  justify?: FlexStyle["justifyContent"];
  align?: FlexStyle["alignItems"];
}

export type AppViewProps = ViewProps & BaseViewProps;

const styles = StyleSheet.create<any>({
  view: (props: AppViewProps, theme: AppTheme) => ({
    backgroundColor: props.bg
      ? getColorFromProvidedTheme(props.bg, theme)
      : undefined,

    width: props.w ? theme.spacing[props.w] || props.w : undefined,
    height: props.h ? theme.spacing[props.h] || props.h : undefined,

    marginLeft: props.ml ? theme.spacing[props.ml] : undefined,
    marginRight: props.mr ? theme.spacing[props.mr] : undefined,
    marginHorizontal: props.mx ? theme.spacing[props.mx] : undefined,
    marginVertical: props.my ? theme.spacing[props.my] : undefined,
    marginTop: props.mt ? theme.spacing[props.mt] : undefined,
    marginBottom: props.mb ? theme.spacing[props.mb] : undefined,
    margin: props.m ? theme.spacing[props.m] : undefined,
    paddingRight: props.pr ? theme.spacing[props.pr] : undefined,
    paddingLeft: props.pl ? theme.spacing[props.pl] : undefined,
    paddingHorizontal: props.px ? theme.spacing[props.px] : undefined,
    paddingVertical: props.py ? theme.spacing[props.py] : undefined,
    paddingTop: props.pt ? theme.spacing[props.pt] : undefined,
    paddingBottom: props.pb ? theme.spacing[props.pb] : undefined,
    padding: props.p ? theme.spacing[props.p] : undefined,
    borderWidth: props.b,
    borderColor: props.bc
      ? getColorFromProvidedTheme(props.bc, theme)
      : undefined,
    borderRadius: props.br,
  }),
  flex: (props: AppViewProps) => ({
    flex: props.flex,
    flexDirection: props.row ? "row" : "column",
    justifyContent: props.justify,
    alignItems: props.align,
  }),
});

export const View = React.forwardRef((props: AppViewProps, forwardedRef) => {
  const theme = useAppTheme();
  return (
    <DefaultView
      {...props}
      ref={forwardedRef as any}
      style={[styles.view(props, theme), styles.flex(props), props.style]}
    />
  );
});

export const ScrollView = React.forwardRef(
  (props: AppViewProps & ScrollViewProps, forwardedRef) => {
    const theme = useAppTheme();
    return (
      <DefaultScrollView
        {...props}
        ref={forwardedRef as any}
        style={[styles.view(props, theme), props.style]}
        contentContainerStyle={[
          styles.flex(props),
          props.contentContainerStyle,
        ]}
      />
    );
  }
);

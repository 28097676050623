import { isNativeMobile } from "../../../../utils/utils.helper";
import { FormBuilderImport } from "./FormBuilder.Import";
import { FormList, FormSectionList } from "./FormBuilder.List";
import type { FormBuilderProps } from "./FormBuilder.types";

export const FormBuilder = <T,>(props: FormBuilderProps<T>) => {
  const { data, form, initialValue, value } = props;
  if (props.flatView || isNativeMobile) {
    return (
      <FormSectionList
        configList={data}
        form={form}
        initialValue={initialValue}
        value={value}
        readonly={props.readonly}
        extensions={props.extensions}
        listStyle={props.listStyle}
        itemStyle={props.itemStyle}
      />
    );
  }

  return (
    <FormList
      configList={data}
      form={form}
      initialValue={initialValue}
      value={value}
      readonly={props.readonly}
      extensions={props.extensions}
      listStyle={props.listStyle}
      itemStyle={props.itemStyle}
    />
  );
};

FormBuilderImport.FormBuilder = FormBuilder;

import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";

export type LangTextInputValue = { [key: string]: string };

export const useLangSelectorForm = (
  props: FormHookProp<LangTextInputValue, "lang-text">
) => {
  return useInputForm(props);
};

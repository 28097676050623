export type NumberFormat = "commas" | "currency" | undefined;

export const convertStringToNumber = (text: string | undefined) => {
  if (text === undefined) {
    return;
  }
  if (Number.isNaN(Number(text))) {
    return undefined;
  }
  return Number(text);
};

export const convertNumberToString = (text: number | undefined) => {
  if (text === undefined) {
    return;
  }
  if (Number.isNaN(Number(text))) {
    return undefined;
  }
  return String(text);
};

export const removeCommas = (text: string) => {
  return text.replace(/[^\d.-]/g, "").replace(/,/g, "");
};
export const formatNumber = (
  value: string | undefined,
  format: NumberFormat
): string | undefined => {
  if (value === undefined) {
    return;
  }

  if (format === "commas") {
    const text = removeCommas(value);
    const decimalIndex = text.indexOf(".");
    const split = decimalIndex === -1 ? undefined : decimalIndex;
    const preDecimal = text.substring(0, split);
    const postDecimal = split ? text.substring(split) : "";

    return preDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + postDecimal;
  }

  return value;
};

import {
  FormGroup,
  FormGroupItem,
  SeparatorType,
} from "@droplet_tech/core-common-types";
import React, { useCallback } from "react";
import {
  FlatList,
  ListRenderItem,
  SectionList,
  SectionListRenderItem,
} from "react-native";
import { useAppTheme } from "../../../../theme";
import { isNativeMobile, isObject } from "../../../../utils/utils.helper";
import { Separator } from "../../../Divider/Divider";
import { HStack } from "../../../Stack";
import { Text } from "../../../Text";
import { View } from "../../../View";
import { FormBuilderListItem } from "./FormBuilder.ListItem";
import type { FormListProp } from "./FormBuilder.types";

export const FormList = <T,>(props: FormListProp<T>) => {
  const { spacing } = useAppTheme();

  const renderItem: ListRenderItem<FormGroupItem> = useCallback(
    ({ item: { items, style, type }, index }) => {
      if (type === "separator") {
        return <Separator style={style} />;
      }

      return (
        <HStack flex={1} space="2.5" align="stretch" style={style}>
          {items.map((item) => {
            const value = props.value
              ? (props.value as any)[item.id]
              : undefined;
            const initialValue = props.initialValue
              ? (props.initialValue as any)[item.id]
              : undefined;

            return (
              <FormBuilderListItem
                key={item.id + index}
                form={props.form}
                config={item}
                initialValue={initialValue}
                value={value}
                style={[props.itemStyle, item.style]}
                readonly={props.readonly}
                extensions={props.extensions}
              />
            );
          })}
        </HStack>
      );
    },
    [props.value]
  );
  const keyExtractor = useCallback(
    (_: FormGroupItem, ix: number) => String(ix),
    []
  );
  return (
    <FlatList
      style={props.listStyle}
      contentContainerStyle={[
        { padding: spacing[3] },
        props.contentContainerStyle,
      ]}
      data={props.configList}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
    />
  );
};

export const FormSectionList = <T,>(props: FormListProp<T>) => {
  const { spacing } = useAppTheme();
  const renderItem: SectionListRenderItem<FormGroup> = useCallback(
    (sectionItem) => {
      const item = sectionItem.item;
      const config = sectionItem.section.config as
        | FormGroupItem
        | SeparatorType;

      if (config.type === "separator") {
        return <Separator style={config.style} />;
      }

      const value = isObject(props.value)
        ? props.value?.[item.id]
        : item.initialValue;
      const initialValue = isObject(props.initialValue)
        ? props.initialValue?.[item.id]
        : item.initialValue;

      return (
        <FormBuilderListItem
          form={props.form}
          config={item}
          initialValue={initialValue}
          value={value}
          style={[props.itemStyle, item.style]}
          readonly={props.readonly}
          extensions={props.extensions}
        />
      );
    },
    [props.value]
  );
  const keyExtractor = useCallback((item: FormGroup) => item.id, []);

  return (
    <SectionList
      contentContainerStyle={[
        { padding: spacing[3] },
        isNativeMobile && {
          paddingBottom: 60,
        },
        props.contentContainerStyle,
      ]}
      style={props.listStyle}
      sections={props.configList.map((config) => {
        if (config.type === "separator") {
          return {
            data: [],
            config,
          };
        }

        return {
          data: config.items,
          label: config.label,
          config,
        };
      })}
      renderSectionHeader={({ section: { label } }) => {
        return label ? (
          <View mb="1">
            <Text.Body1Regular color="monochrome-mid">
              {label}
            </Text.Body1Regular>
          </View>
        ) : null;
      }}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
    />
  );
};

import type { CommonInputProps } from "../Form/FormBuilder/FormBuilder.types";
import { FormInput } from "../FormInputs/FormInput";
import { useNumberForm } from "./NumberInput.hook";
import { formatNumber } from "./NumberInput.utils";

export interface NumberInputProps extends CommonInputProps<string, "number"> {
  // formOptions?: FormHookProp<string> & { format: NumberFormat };
}

export const NumberInput = (formInputProps: NumberInputProps) => {
  const {
    value,
    setValue,
    isValid,
    feedback,
    error,
    // allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    config,
    noFeedback,
  } = useNumberForm(formInputProps);
  const numValue = formatNumber(value, config.format);
  return (
    <FormInput
      noFeedback={noFeedback}
      autoCorrect={false}
      autoCapitalize="none"
      keyboardType="number-pad"
      value={numValue}
      disabled={disabled}
      label={label}
      optional={optional}
      readonly={readonly}
      onChangeText={(txt) => {
        setValue(txt);
      }}
      feedback={feedback}
      error={error}
      onBlur={() => {
        isValid({ showFeedback: true, onBlur: true });
      }}
    />
  );
};

import { translate } from "../../lang/lang";
import { useAppTheme } from "../../theme";
import type { ChildrenProp } from "../../types/components";
import { Button } from "../Button/Button";
import { Divider } from "../Divider/Divider";
import { HStack } from "../Stack";
import { View } from "../View";

export interface ModalFooterProps extends ChildrenProp {
  onConfirm?: () => void;
  confirm?: string;
  cancel?: string;
  onCancel?: () => void;
  divider?: boolean;
  isLoading?: boolean;
}

export const ModalFooter = ({
  children,
  divider,
  onConfirm,
  confirm,
  cancel,
  onCancel,
  isLoading = false,
}: ModalFooterProps) => {
  const modalFooterProps = useAppTheme().components.modalFooter;
  return (
    <>
      {divider ? <Divider /> : <View />}
      <HStack {...modalFooterProps?.container}>
        <HStack flex={1}>{children}</HStack>
        {onCancel && !isLoading ? (
          <Button {...modalFooterProps?.cancel} onPress={onCancel}>
            {cancel || translate("app.inputs.cancel")}
          </Button>
        ) : null}
        {onConfirm ? (
          <Button
            isLoading={isLoading}
            {...modalFooterProps?.confirm}
            onPress={onConfirm}
          >
            {confirm || translate("app.inputs.confirm")}
          </Button>
        ) : null}
      </HStack>
    </>
  );
};

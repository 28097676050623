import {
  AppTheme,
  ColorCategory,
  ColorKeys,
  getColorFromProvidedTheme,
} from "../../theme";
import { getColorShade } from "../../utils/utils.ui";
import type { IconVariant } from "./Icon.types";

export const getIconBackgroundColor = ({
  theme,
  color,
  variant,
  disabled,
  hovered,
}: {
  theme: AppTheme;
  color: ColorCategory;
  variant: IconVariant;
  disabled?: boolean;
  hovered?: boolean;
}) => {
  const disabledColor = theme.palette.primary.base;

  switch (variant) {
    case "filled": {
      const bgKey = getColorShade(color, hovered ? "dark" : "mid");
      const mainColor = getColorFromProvidedTheme(bgKey, theme);
      return disabled ? disabledColor : mainColor;
    }

    case "semi-filled": {
      return getColorFromProvidedTheme(
        getColorShade(color, hovered ? "extraLight" : "base"),
        theme
      );
    }

    case "plain":
    default: {
      return hovered
        ? getColorFromProvidedTheme(getColorShade(color, "base"), theme)
        : "transparent";
    }
  }
};

export const getIconTextColor = ({
  disabled,
  color,
  variant,
}: {
  color: ColorCategory;
  variant: IconVariant;
  disabled?: boolean;
}): ColorKeys => {
  switch (variant) {
    case "filled": {
      return disabled ? "monochrome-light" : "white";
    }

    case "plain": {
      return disabled ? "monochrome-light" : getColorShade(color, "mid");
    }

    case "semi-filled":
    default: {
      return disabled ? "monochrome-light" : getColorShade(color, "mid");
    }
  }
};

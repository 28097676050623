import { isWeb } from "../../../utils/utils.helper";
import * as DocumentPicker from "expo-document-picker";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import type { DocumentPickerValue } from "./DocumentPicker.types";

interface ReconstructedFilename {
  name: string;
  fileNumber?: number;
  extension?: string;
}

const reconstructFileName = (name: string): ReconstructedFilename => {
  const extensionIndex = name.lastIndexOf(".");
  const extension = extensionIndex === -1 ? "" : name.slice(extensionIndex);
  const partialName1 =
    extensionIndex === -1 ? name : name.slice(0, extensionIndex);

  const openingParenIndex = name.lastIndexOf("(");
  const closingParenIndex = name.lastIndexOf(")");
  const hasClosingParen = partialName1.slice(-1) === ")";

  const fileNumberStr = partialName1.slice(
    openingParenIndex + 1,
    closingParenIndex
  );

  const fileNumber = Number(fileNumberStr);
  if (!Number.isNaN(Number(fileNumber)) && hasClosingParen) {
    const partialName2 = partialName1.slice(0, openingParenIndex);
    return { name: partialName2, extension, fileNumber };
  }

  return { name: partialName1, extension };
};

const getUpToDateFileName = (
  newFileName: string,
  files: DocumentPickerValue
) => {
  const { name, fileNumber, extension } = reconstructFileName(newFileName);

  const fileNames = files
    .map((file) => reconstructFileName(file.name))
    .filter((file) => file.name === name);

  const flatNumbers = fileNames.map((fileName) => fileName.fileNumber ?? 0);

  const maxIndex = Math.max(...(flatNumbers.length ? flatNumbers : [0]));
  return name + `(${Math.max(maxIndex + 1, fileNumber ?? 1)})` + extension;
};

export const extractFileFromEvent = (
  fileList: FileList | undefined | null,
  files: DocumentPickerValue
) => {
  if (fileList) {
    const uploadedFile = fileList[0];
    const formData = new FormData();
    const doesFileNameExistAlready = files.some(
      (f) => f.name === uploadedFile.name
    );

    const reconstructedName = getUpToDateFileName(uploadedFile.name, files);
    const newNameFile = doesFileNameExistAlready
      ? reconstructedName
      : uploadedFile.name;

    formData.append("file", uploadedFile as any, newNameFile);
    return formData;
  }
};

export const onFilePicker = async ({
  addFile,
  files,
}: {
  addFile: (_: FormData) => void;
  files: DocumentPickerValue;
}) => {
  if (isWeb) {
    /**
     * WEB version
     */
    const input = document.createElement("input");
    input.type = "file";
    input.tabIndex = -1;

    (input as any).onchange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const formData = extractFileFromEvent(ev.target.files, files);

      if (formData) {
        addFile(formData);
      }
    };
    input.click();
  } else {
    /**
     * Native version version
     */
    const file = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
    });

    const formData = new FormData();

    if (file.type === "success") {
      if (file.mimeType?.indexOf("image") !== -1) {
        const imageFile = await manipulateAsync(file.uri, [], {
          compress: 0.2,
          format: SaveFormat.JPEG,
        });

        formData.append("file", {
          uri: imageFile.uri, // this is the path to your file. see Expo ImagePicker or React Native ImagePicker
          type: "image/jpeg",
          name: file.name.slice(0, file.name.lastIndexOf(".")) + ".jpg", // example: upload.jpg
          size: file.size,
        } as any);
      } else {
        formData.append("file", {
          uri: file.uri, // this is the path to your file. see Expo ImagePicker or React Native ImagePicker
          type: file.mimeType || "*/*", // example: image/jpg
          name: file.name, // example: upload.jpg
          size: file.size || 0,
        } as any);
      }

      addFile(formData);
    }
  }
};

export const onFileSelect = (file: File) => {
  const objectUrl = URL.createObjectURL(file);
  const linkEl = document.createElement("a");
  linkEl.download = "";
  linkEl.href = objectUrl;
  linkEl.click();
};

export const getFileFromFormData = (formData: FormData) => {
  const documentFile = isWeb
    ? formData.get("file")
    : (formData as any).getParts()?.[0];

  if (!!documentFile && typeof documentFile !== "string") {
    return documentFile;
  }
};

import { AxiosError } from "axios";
import { translate } from "../../lang/lang";

export interface ErrorResponse {
  code: string;
  message: string;
}

export interface RTKError {
  status: number;
  data: ErrorResponse;
}
export interface RTKErrorResponse {
  error: unknown;
}

export function isRTKErrorResponse(res: any): res is RTKErrorResponse {
  return typeof res === "object" && typeof res.error === "object";
}

export function isRTKError(error: any): error is RTKError {
  return (
    typeof error.data === "object" &&
    typeof error.status === "number" &&
    isErrorResponse(error.data)
  );
}

export function isErrorResponse(error: any): error is ErrorResponse {
  return (
    typeof error === "object" &&
    typeof error.code === "string" &&
    typeof error.message === "string"
  );
}

export function getErrorMessageAndCode(error: unknown): ErrorResponse {
  if (isRTKErrorResponse(error)) {
    return getErrorMessageAndCode(error.error);
  }
  if (isRTKError(error)) {
    return error.data;
  }

  if (isErrorResponse(error)) {
    return error;
  }

  return {
    code: "-1",
    message: translate("app.feedback.genericError"),
  };
}

export function getErrorMessage(error: unknown) {
  if (isRTKErrorResponse(error)) {
    return getErrorMessage(error.error);
  }
  if (isRTKError(error)) {
    return error.data.message;
  }

  if (isErrorResponse(error)) {
    return error.message;
  }

  return translate("app.feedback.genericError");
}

export interface ErrorProps {
  defaultMessage: string;
  error?: any;
}

export function isAxiosFetchError(error: any): error is AxiosError {
  return typeof error === "object" && error.hasOwnProperty("response");
}

export const createErrorResponse = ({
  error,
  defaultMessage,
}: ErrorProps): ErrorResponse => {
  if (!error) {
    return { message: defaultMessage, code: "0" };
  }

  if (isAxiosFetchError(error)) {
    const data = error.response?.data;
    if (isErrorResponse(data)) {
      return data;
    }
    return {
      message: defaultMessage,
      code: "0",
    };
  }

  if (isErrorResponse(error)) {
    return { code: error.code, message: error.message };
  }
  return {
    message: defaultMessage,
    code: "0",
  };
};

import React, { ReactNode } from "react";
import { StyleSheet, TextStyle } from "react-native";
import { AppTheme, useAppTheme } from "../../../theme";
import type { ChildrenProp, ViewStyle } from "../../../types/components";
import { Icon } from "../../Icon/Icon";
import { IconsId } from "../../Icon/Icon.map";
import { getIconTextColor } from "../../Icon/Icon.utils";
import { View } from "../../View";

export interface BaseInputWrapper extends ChildrenProp {
  iconStart?: IconsId | Omit<React.ReactNode, string>;
  iconEnd?: IconsId | Omit<React.ReactNode, string>;
  disabled?: boolean;
  error?: boolean;
  inputStyle?: TextStyle;
  inputContainerStyle?: ViewStyle;
  focused?: boolean;
  editable?: boolean;
}

const getIconNode = (
  icon: IconsId | Omit<ReactNode, string> | undefined,
  direction: "left" | "right",
  disabled: boolean
): ReactNode => {
  if (!icon) {
    return null;
  }

  const iconColorKey = getIconTextColor({
    disabled,
    color: "monochrome",
    variant: "plain",
  });

  return (
    <View
      style={direction === "left" ? styles.iconStartStyle : styles.iconEndStyle}
    >
      {typeof icon === "string" ? (
        <Icon icon={icon as IconsId} color={iconColorKey} />
      ) : (
        (icon as React.ReactNode)
      )}
    </View>
  );
};

const styles = StyleSheet.create<any>({
  focusWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.primary.mid,
  }),
  errorWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.error.dark,
  }),
  disabledWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.monochrome.extraLight,
    backgroundColor: theme.palette.monochrome.base,
  }),

  input: (theme: AppTheme) => ({
    ...theme.typography.body2Regular,
    flex: 1,
    color: theme.palette.black,
  }),
  // errorInput: (theme: AppTheme) => ({
  //   color: theme.palette.error.dark,
  // }),

  disableInput: (theme: AppTheme) => ({
    color: theme.palette.monochrome.extraLight,
  }),

  iconStartStyle: {
    marginRight: 10,
  },
  iconEndStyle: {
    marginLeft: 10,
  },
});

export const BaseInputWrapper = ({
  disabled = false,
  // editable = true,
  inputContainerStyle,
  iconStart,
  iconEnd,
  error,
  focused,
  children,
}: BaseInputWrapper) => {
  const theme = useAppTheme();
  return (
    <View
      style={[
        theme.components.textInput,
        inputContainerStyle,
        error && styles.errorWrapper(theme),
        focused && styles.focusWrapper(theme),
        disabled && styles.disabledWrapper(theme),
        { overflow: "hidden" },
      ]}
    >
      {getIconNode(iconStart, "left", disabled)}
      {children}
      {getIconNode(iconEnd, "right", disabled)}
    </View>
  );
};

import { BaseInputProps } from "../BaseInputs/BaseInput";
import { CommonInputProps } from "../Form/FormBuilder/FormBuilder.types";
import { FormInput } from "../FormInputs/FormInput";
import { useTextForm } from "./TextInput.hooks";

export interface TextInputProps extends CommonInputProps<string, "text"> {}

export const TextInput = ({
  secureTextEntry,
  iconEnd,
  iconStart,
  ...props
}: TextInputProps & {
  secureTextEntry?: BaseInputProps["secureTextEntry"];
  iconEnd?: BaseInputProps["iconEnd"];
  iconStart?: BaseInputProps["iconStart"];
}) => {
  const {
    value,
    setValue,
    isValid,
    feedback,
    error,
    // allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
  } = useTextForm(props);
  const multiline = props.config.multiline;
  return (
    <FormInput
      noFeedback={noFeedback}
      autoCorrect={false}
      autoCapitalize="none"
      value={value}
      feedback={feedback}
      error={error}
      disabled={disabled}
      label={label}
      optional={optional}
      readonly={readonly}
      onChangeText={(txt) => setValue(txt)}
      onBlur={() => {
        isValid({ showFeedback: true, onBlur: true });
        // formProps.onBlur?.(e);
      }}
      numberOfLines={multiline}
      multiline={typeof multiline === "number"}
      iconStart={iconStart}
      iconEnd={iconEnd}
      secureTextEntry={secureTextEntry}
    />
  );
};

import { translate } from "../../../lang/lang";
import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";
import {
  convertNumberToString,
  convertStringToNumber,
  removeCommas,
} from "./NumberInput.utils";

export const useNumberForm = (props: FormHookProp<string, "number">) => {
  return useInputForm({
    ...props,
    initialValue: convertNumberToString(
      (props.initialValue ?? props.config.initialValue) as number | undefined
    ),
    value: convertNumberToString(props.value as number | undefined),
    test: (numberText) => {
      return !Number.isNaN(Number(removeCommas(numberText)));
    },
    errorFeedback: translate("app.inputs.invalidNumber"),
    getValue: (txt) => {
      const newNumber =
        txt === "" || txt === undefined
          ? undefined
          : convertStringToNumber(removeCommas(txt));
      return newNumber;
    },
  });
};

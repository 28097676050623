import { StyleSheet } from "react-native";

export const modalStyles = StyleSheet.create({
  popupWrapper: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  actionSheetWrapper: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "flex-end",
    alignItems: "stretch",
    backgroundColor: "transparent",
  },
  fullActionSheetWrapper: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "transparent",
  },
  pressable: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "transparent",
  },
  modal: {
    shadowColor: "#000000",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    backgroundColor: "white",
  },
  actionSheet: {
    shadowColor: "#000000",
    shadowOffset: { width: 1, height: -1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    backgroundColor: "white",
  },
});

import { useAppTheme } from "./Theme.hooks";
import type {
  AppTheme,
  ColorCategory,
  ColorKeys,
  ColorShades,
} from "./Theme.types";

export const useColorFromTheme = (colorKey: ColorKeys): string => {
  const theme = useAppTheme();
  return getColorFromProvidedTheme(colorKey, theme);
};

export const getColorFromProvidedTheme = (
  colorKey: ColorKeys,
  theme: AppTheme
) => {
  const colorSelective = colorKey.split("-") as [ColorCategory, ColorShades];
  const colorCategory = colorSelective[0];
  const colorShade: ColorShades = colorSelective[1] || "mid";

  if (!colorSelective[1] && typeof theme.palette[colorCategory] === "string") {
    return theme.palette[colorCategory] as unknown as string;
  }
  return theme.palette[colorCategory][colorShade];
};

import { useContext, useEffect, useState } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { dayjs } from "../../utils/utils.date";
import { CalenderContext } from "./CalenderManager.Context";
import type {
  CalenderDayItemManagerReturnProps,
  CalenderIndexHeaderReturnProps,
  CalenderManagerBaseProps,
  CalenderManagerContextProps,
  CalenderMarkedDateOptions,
  HeaderOptions,
} from "./CalenderManager.types";

export const useCalenderManager = () => {
  return useContext(CalenderContext);
};

/**
 * @param dateString YYYY-MM-DD
 */
export const useCalenderDayItemManager = ({
  dateString,
  calenderManager,
}: CalenderManagerContextProps): CalenderDayItemManagerReturnProps => {
  const [markedDateOptions, onChangeDateOptions] =
    useState<CalenderMarkedDateOptions>(
      (dateString && (calenderManager as any)._markedDates[dateString]) || {}
    );

  // OnMount
  useEffect(() => {
    if (!dateString) {
      return;
    }

    const onSetState = (newMarkedDates: CalenderMarkedDateOptions) => {
      onChangeDateOptions(newMarkedDates);
    };

    calenderManager.registerChildSetState(dateString, onSetState as any);
    // OnDismount
    return () => {
      calenderManager.deregisterChildSetState(dateString, onSetState as any);
    };
  }, [dateString]);

  const isToday =
    dayjs().format(calenderManager.getDateFormat()) === dateString;

  return {
    /**
     * @param dateString YYYY-MM-DD
     */
    onDateSelect: () => {
      if (dateString) {
        calenderManager.onDateSelect(dateString);
      }
    },
    markedDateOptions,
    selectedHeader:
      calenderManager.getHeaderFromMarkedDateOptions(markedDateOptions),

    isToday,
    isBeforeToday: dateString
      ? calenderManager.isBeforeToday(dateString) && !isToday
      : false,
  };
};

const initialHeaderOptions: HeaderOptions = {
  dateNumbers: [],
  selectedIndex: 0,
  selectedDates: [],
  selectedHeaders: [],
};
const { useGlobalState: useSelectiveIndexHeaderState } = createGlobalState({
  headerOptions: initialHeaderOptions,
  mounted: false,
});

export const useCalenderSelectiveIndexHeader = ({
  calenderManager,
}: CalenderManagerBaseProps): CalenderIndexHeaderReturnProps => {
  const [headerOptions, setHeaderOptions] =
    useSelectiveIndexHeaderState("headerOptions");
  const [isMounted, setMounted] = useSelectiveIndexHeaderState("mounted");

  // OnMount
  useEffect(() => {
    const onSetState = (options: HeaderOptions) => {
      setHeaderOptions(options);
    };

    if (headerOptions.selectedHeaders.length === 0 && isMounted === false) {
      setMounted(true);
      setHeaderOptions({
        ...headerOptions,
        selectedDates: calenderManager
          .getSelectedDates()
          .map((item) => item.toJSON()),
        dateNumbers: calenderManager.getDateNumbers(
          calenderManager.getSelectedDates()
        ),
        selectedIndex: calenderManager.getSelectedIndex(),
        selectedHeaders: calenderManager.getSelectiveHeaders(),
      });
    }

    calenderManager.registerHeaderSetState(onSetState as any);
    // OnDismount
    return () => {
      setMounted(false);
      setHeaderOptions(initialHeaderOptions);
      calenderManager.deregisterHeaderSetState();
    };
  }, []);

  return {
    setSelectedIndex: calenderManager.setSelectedIndex,
    setSelectedHeaders: calenderManager.setSelectedHeaders,
    ...headerOptions,
  };
};

import React from "react";
import { useAppTheme } from "../../theme";
import { ModalBackdrop } from "./Modal.Backdrop";
import { modalStyles } from "./Modal.styles";
import type { ModalProps } from "./Modal.types";

export const ModalPopup = (props: ModalProps) => {
  const modalPopupStyle = useAppTheme().components.modalPopup;
  return (
    <ModalBackdrop
      {...props}
      type="pop-up"
      modalStyle={[modalStyles.modal, modalPopupStyle, props.modalStyle]}
    />
  );
};

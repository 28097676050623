export type IconsId =
  | "add"
  | "agent"
  | "air-conditioning"
  | "airport"
  | "alacarte"
  | "apple"
  | "area-v2"
  | "area"
  | "arrow-back"
  | "arrow-down"
  | "arrow-forward"
  | "arrow-group"
  | "arrowdown"
  | "bank-refund"
  | "bar"
  | "barcode-disable"
  | "bed-cabin"
  | "bed"
  | "bicycle"
  | "bin"
  | "boarding-card"
  | "book"
  | "bookmark"
  | "build-country"
  | "build-year"
  | "bus"
  | "cabins"
  | "calendar"
  | "calender"
  | "call"
  | "camper-side-view"
  | "camper"
  | "captain"
  | "car-side-view"
  | "card-disable"
  | "charter-in"
  | "charter-out"
  | "check"
  | "checkboxoff"
  | "checkboxon"
  | "checkin-v2"
  | "checkin-v3"
  | "checkin"
  | "checklist"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevron-up"
  | "clock"
  | "close"
  | "company-contact-information-2"
  | "company-contact-information"
  | "complete"
  | "contacts"
  | "contract-complete"
  | "contract-edit"
  | "convert-to-open"
  | "copy"
  | "courier"
  | "covid-v1"
  | "covid-v2"
  | "create-a-support-request"
  | "cruise-speed"
  | "customer-support-v2"
  | "customer-support"
  | "deck"
  | "departure"
  | "details"
  | "direct"
  | "disable-ferry"
  | "disable-online-check-in"
  | "disconnected"
  | "docked"
  | "dog"
  | "download-a-receipt"
  | "download-pdf"
  | "drink"
  | "e-ticket"
  | "e-tickets"
  | "economy-cabins"
  | "edit"
  | "email"
  | "error"
  | "euro"
  | "extra-general"
  | "extra-v2"
  | "extra"
  | "favorite-disable"
  | "favorite"
  | "feedback"
  | "ferry-track"
  | "ferry"
  | "file"
  | "filter"
  | "finish"
  | "food"
  | "get-a-booking-confirmation"
  | "google"
  | "have-papers-ready"
  | "hide"
  | "history"
  | "home"
  | "hotel"
  | "id-card"
  | "image"
  | "insurance"
  | "invoice"
  | "language"
  | "late"
  | "leaf"
  | "length"
  | "link"
  | "live-tracking"
  | "location-pin"
  | "lock"
  | "lounge"
  | "luggage-v2"
  | "luggage"
  | "luxury-cabin"
  | "mastercard"
  | "meal"
  | "message"
  | "middle"
  | "minibus-side-view"
  | "minibus"
  | "mobile"
  | "modify-or-cancel-my-booking"
  | "motorcycle-side-view"
  | "motorcycle"
  | "move"
  | "multi-trip"
  | "natural-disaster-v1"
  | "natural-disaster-v2"
  | "navigation"
  | "no-bed"
  | "no-details"
  | "no-e-ticket"
  | "no-internet"
  | "no-vehicle"
  | "non-amendable"
  | "non-charter-in"
  | "non-charter-out"
  | "non-refundable"
  | "notice"
  | "notification"
  | "numbered-location-pin"
  | "numbered-seats"
  | "office"
  | "onboard-facilities-v2"
  | "onboard-facilities"
  | "online-checki-in"
  | "other"
  | "paint"
  | "passanger-capacity"
  | "passanger-v2"
  | "passanger"
  | "payment"
  | "pdf"
  | "pending-transaction"
  | "people"
  | "pet-cabin"
  | "pet-cabins"
  | "pet-disable"
  | "pet-info"
  | "pet-paw"
  | "pets-allowed"
  | "pin-disable-v2"
  | "pin-disable"
  | "pin-v2"
  | "pin"
  | "plus"
  | "popular-routes"
  | "price"
  | "print-ticket"
  | "qr-code"
  | "question-mark"
  | "railway"
  | "rating"
  | "receipt"
  | "receive-a-bank-refund"
  | "receive-a-company-invoice"
  | "receive-a-full-voucher"
  | "receive-a-receipt"
  | "refresh"
  | "refurb-country"
  | "refurb-year"
  | "result"
  | "retrieve-booking-confirmation"
  | "return"
  | "reward"
  | "route"
  | "save"
  | "search"
  | "seats"
  | "see-1"
  | "see-2"
  | "see-the-ferry-terms"
  | "see-the-partnering-agencies"
  | "see"
  | "send"
  | "setting-gear"
  | "share"
  | "shops"
  | "sign-in-or-register"
  | "sign-out"
  | "single-seat"
  | "single-trip"
  | "sort-by"
  | "star"
  | "start"
  | "strikes-v1"
  | "strikes-v2"
  | "support-closed"
  | "terms-of-use"
  | "thumbs-rating"
  | "ticket-new"
  | "ticket-offers"
  | "ticket"
  | "to-do-list"
  | "toolkit"
  | "top-speed"
  | "track"
  | "trailer-side-view"
  | "trailer"
  | "trip-cancellation"
  | "up-arrow"
  | "veh-cap"
  | "vehicle-capacity"
  | "vehicle-v2"
  | "vehicle"
  | "vehicles"
  | "verify"
  | "vessel-type"
  | "visa"
  | "voucher-refund"
  | "voucher"
  | "wallet"
  | "warning"
  | "weather-disruptions-v1"
  | "weather-disruptions-v2"
  | "width"
  | "wifi"
  | "worldwide";

export type IconsKey =
  | "Add"
  | "Agent"
  | "AirConditioning"
  | "Airport"
  | "Alacarte"
  | "Apple"
  | "AreaV2"
  | "Area"
  | "ArrowBack"
  | "ArrowDown"
  | "ArrowForward"
  | "ArrowGroup"
  | "Arrowdown"
  | "BankRefund"
  | "Bar"
  | "BarcodeDisable"
  | "BedCabin"
  | "Bed"
  | "Bicycle"
  | "Bin"
  | "BoardingCard"
  | "Book"
  | "Bookmark"
  | "BuildCountry"
  | "BuildYear"
  | "Bus"
  | "Cabins"
  | "Calendar"
  | "Calender"
  | "Call"
  | "CamperSideView"
  | "Camper"
  | "Captain"
  | "CarSideView"
  | "CardDisable"
  | "CharterIn"
  | "CharterOut"
  | "Check"
  | "Checkboxoff"
  | "Checkboxon"
  | "CheckinV2"
  | "CheckinV3"
  | "Checkin"
  | "Checklist"
  | "ChevronDown"
  | "ChevronLeft"
  | "ChevronRight"
  | "ChevronUp"
  | "Clock"
  | "Close"
  | "CompanyContactInformation_2"
  | "CompanyContactInformation"
  | "Complete"
  | "Contacts"
  | "ContractComplete"
  | "ContractEdit"
  | "ConvertToOpen"
  | "Copy"
  | "Courier"
  | "CovidV1"
  | "CovidV2"
  | "CreateASupportRequest"
  | "CruiseSpeed"
  | "CustomerSupportV2"
  | "CustomerSupport"
  | "Deck"
  | "Departure"
  | "Details"
  | "Direct"
  | "DisableFerry"
  | "DisableOnlineCheckIn"
  | "Disconnected"
  | "Docked"
  | "Dog"
  | "DownloadAReceipt"
  | "DownloadPdf"
  | "Drink"
  | "ETicket"
  | "ETickets"
  | "EconomyCabins"
  | "Edit"
  | "Email"
  | "Error"
  | "Euro"
  | "ExtraGeneral"
  | "ExtraV2"
  | "Extra"
  | "FavoriteDisable"
  | "Favorite"
  | "Feedback"
  | "FerryTrack"
  | "Ferry"
  | "File"
  | "Filter"
  | "Finish"
  | "Food"
  | "GetABookingConfirmation"
  | "Google"
  | "HavePapersReady"
  | "Hide"
  | "History"
  | "Home"
  | "Hotel"
  | "IdCard"
  | "Image"
  | "Insurance"
  | "Invoice"
  | "Language"
  | "Late"
  | "Leaf"
  | "Length"
  | "Link"
  | "LiveTracking"
  | "LocationPin"
  | "Lock"
  | "Lounge"
  | "LuggageV2"
  | "Luggage"
  | "LuxuryCabin"
  | "Mastercard"
  | "Meal"
  | "Message"
  | "Middle"
  | "MinibusSideView"
  | "Minibus"
  | "Mobile"
  | "ModifyOrCancelMyBooking"
  | "MotorcycleSideView"
  | "Motorcycle"
  | "Move"
  | "MultiTrip"
  | "NaturalDisasterV1"
  | "NaturalDisasterV2"
  | "Navigation"
  | "NoBed"
  | "NoDetails"
  | "NoETicket"
  | "NoInternet"
  | "NoVehicle"
  | "NonAmendable"
  | "NonCharterIn"
  | "NonCharterOut"
  | "NonRefundable"
  | "Notice"
  | "Notification"
  | "NumberedLocationPin"
  | "NumberedSeats"
  | "Office"
  | "OnboardFacilitiesV2"
  | "OnboardFacilities"
  | "OnlineCheckiIn"
  | "Other"
  | "Paint"
  | "PassangerCapacity"
  | "PassangerV2"
  | "Passanger"
  | "Payment"
  | "Pdf"
  | "PendingTransaction"
  | "People"
  | "PetCabin"
  | "PetCabins"
  | "PetDisable"
  | "PetInfo"
  | "PetPaw"
  | "PetsAllowed"
  | "PinDisableV2"
  | "PinDisable"
  | "PinV2"
  | "Pin"
  | "Plus"
  | "PopularRoutes"
  | "Price"
  | "PrintTicket"
  | "QrCode"
  | "QuestionMark"
  | "Railway"
  | "Rating"
  | "Receipt"
  | "ReceiveABankRefund"
  | "ReceiveACompanyInvoice"
  | "ReceiveAFullVoucher"
  | "ReceiveAReceipt"
  | "Refresh"
  | "RefurbCountry"
  | "RefurbYear"
  | "Result"
  | "RetrieveBookingConfirmation"
  | "Return"
  | "Reward"
  | "Route"
  | "Save"
  | "Search"
  | "Seats"
  | "See_1"
  | "See_2"
  | "SeeTheFerryTerms"
  | "SeeThePartneringAgencies"
  | "See"
  | "Send"
  | "SettingGear"
  | "Share"
  | "Shops"
  | "SignInOrRegister"
  | "SignOut"
  | "SingleSeat"
  | "SingleTrip"
  | "SortBy"
  | "Star"
  | "Start"
  | "StrikesV1"
  | "StrikesV2"
  | "SupportClosed"
  | "TermsOfUse"
  | "ThumbsRating"
  | "TicketNew"
  | "TicketOffers"
  | "Ticket"
  | "ToDoList"
  | "Toolkit"
  | "TopSpeed"
  | "Track"
  | "TrailerSideView"
  | "Trailer"
  | "TripCancellation"
  | "UpArrow"
  | "VehCap"
  | "VehicleCapacity"
  | "VehicleV2"
  | "Vehicle"
  | "Vehicles"
  | "Verify"
  | "VesselType"
  | "Visa"
  | "VoucherRefund"
  | "Voucher"
  | "Wallet"
  | "Warning"
  | "WeatherDisruptionsV1"
  | "WeatherDisruptionsV2"
  | "Width"
  | "Wifi"
  | "Worldwide";

export enum Icons {
  Add = "add",
  Agent = "agent",
  AirConditioning = "air-conditioning",
  Airport = "airport",
  Alacarte = "alacarte",
  Apple = "apple",
  AreaV2 = "area-v2",
  Area = "area",
  ArrowBack = "arrow-back",
  ArrowDown = "arrow-down",
  ArrowForward = "arrow-forward",
  ArrowGroup = "arrow-group",
  Arrowdown = "arrowdown",
  BankRefund = "bank-refund",
  Bar = "bar",
  BarcodeDisable = "barcode-disable",
  BedCabin = "bed-cabin",
  Bed = "bed",
  Bicycle = "bicycle",
  Bin = "bin",
  BoardingCard = "boarding-card",
  Book = "book",
  Bookmark = "bookmark",
  BuildCountry = "build-country",
  BuildYear = "build-year",
  Bus = "bus",
  Cabins = "cabins",
  Calendar = "calendar",
  Calender = "calender",
  Call = "call",
  CamperSideView = "camper-side-view",
  Camper = "camper",
  Captain = "captain",
  CarSideView = "car-side-view",
  CardDisable = "card-disable",
  CharterIn = "charter-in",
  CharterOut = "charter-out",
  Check = "check",
  Checkboxoff = "checkboxoff",
  Checkboxon = "checkboxon",
  CheckinV2 = "checkin-v2",
  CheckinV3 = "checkin-v3",
  Checkin = "checkin",
  Checklist = "checklist",
  ChevronDown = "chevron-down",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  ChevronUp = "chevron-up",
  Clock = "clock",
  Close = "close",
  CompanyContactInformation_2 = "company-contact-information-2",
  CompanyContactInformation = "company-contact-information",
  Complete = "complete",
  Contacts = "contacts",
  ContractComplete = "contract-complete",
  ContractEdit = "contract-edit",
  ConvertToOpen = "convert-to-open",
  Copy = "copy",
  Courier = "courier",
  CovidV1 = "covid-v1",
  CovidV2 = "covid-v2",
  CreateASupportRequest = "create-a-support-request",
  CruiseSpeed = "cruise-speed",
  CustomerSupportV2 = "customer-support-v2",
  CustomerSupport = "customer-support",
  Deck = "deck",
  Departure = "departure",
  Details = "details",
  Direct = "direct",
  DisableFerry = "disable-ferry",
  DisableOnlineCheckIn = "disable-online-check-in",
  Disconnected = "disconnected",
  Docked = "docked",
  Dog = "dog",
  DownloadAReceipt = "download-a-receipt",
  DownloadPdf = "download-pdf",
  Drink = "drink",
  ETicket = "e-ticket",
  ETickets = "e-tickets",
  EconomyCabins = "economy-cabins",
  Edit = "edit",
  Email = "email",
  Error = "error",
  Euro = "euro",
  ExtraGeneral = "extra-general",
  ExtraV2 = "extra-v2",
  Extra = "extra",
  FavoriteDisable = "favorite-disable",
  Favorite = "favorite",
  Feedback = "feedback",
  FerryTrack = "ferry-track",
  Ferry = "ferry",
  File = "file",
  Filter = "filter",
  Finish = "finish",
  Food = "food",
  GetABookingConfirmation = "get-a-booking-confirmation",
  Google = "google",
  HavePapersReady = "have-papers-ready",
  Hide = "hide",
  History = "history",
  Home = "home",
  Hotel = "hotel",
  IdCard = "id-card",
  Image = "image",
  Insurance = "insurance",
  Invoice = "invoice",
  Language = "language",
  Late = "late",
  Leaf = "leaf",
  Length = "length",
  Link = "link",
  LiveTracking = "live-tracking",
  LocationPin = "location-pin",
  Lock = "lock",
  Lounge = "lounge",
  LuggageV2 = "luggage-v2",
  Luggage = "luggage",
  LuxuryCabin = "luxury-cabin",
  Mastercard = "mastercard",
  Meal = "meal",
  Message = "message",
  Middle = "middle",
  MinibusSideView = "minibus-side-view",
  Minibus = "minibus",
  Mobile = "mobile",
  ModifyOrCancelMyBooking = "modify-or-cancel-my-booking",
  MotorcycleSideView = "motorcycle-side-view",
  Motorcycle = "motorcycle",
  Move = "move",
  MultiTrip = "multi-trip",
  NaturalDisasterV1 = "natural-disaster-v1",
  NaturalDisasterV2 = "natural-disaster-v2",
  Navigation = "navigation",
  NoBed = "no-bed",
  NoDetails = "no-details",
  NoETicket = "no-e-ticket",
  NoInternet = "no-internet",
  NoVehicle = "no-vehicle",
  NonAmendable = "non-amendable",
  NonCharterIn = "non-charter-in",
  NonCharterOut = "non-charter-out",
  NonRefundable = "non-refundable",
  Notice = "notice",
  Notification = "notification",
  NumberedLocationPin = "numbered-location-pin",
  NumberedSeats = "numbered-seats",
  Office = "office",
  OnboardFacilitiesV2 = "onboard-facilities-v2",
  OnboardFacilities = "onboard-facilities",
  OnlineCheckiIn = "online-checki-in",
  Other = "other",
  Paint = "paint",
  PassangerCapacity = "passanger-capacity",
  PassangerV2 = "passanger-v2",
  Passanger = "passanger",
  Payment = "payment",
  Pdf = "pdf",
  PendingTransaction = "pending-transaction",
  People = "people",
  PetCabin = "pet-cabin",
  PetCabins = "pet-cabins",
  PetDisable = "pet-disable",
  PetInfo = "pet-info",
  PetPaw = "pet-paw",
  PetsAllowed = "pets-allowed",
  PinDisableV2 = "pin-disable-v2",
  PinDisable = "pin-disable",
  PinV2 = "pin-v2",
  Pin = "pin",
  Plus = "plus",
  PopularRoutes = "popular-routes",
  Price = "price",
  PrintTicket = "print-ticket",
  QrCode = "qr-code",
  QuestionMark = "question-mark",
  Railway = "railway",
  Rating = "rating",
  Receipt = "receipt",
  ReceiveABankRefund = "receive-a-bank-refund",
  ReceiveACompanyInvoice = "receive-a-company-invoice",
  ReceiveAFullVoucher = "receive-a-full-voucher",
  ReceiveAReceipt = "receive-a-receipt",
  Refresh = "refresh",
  RefurbCountry = "refurb-country",
  RefurbYear = "refurb-year",
  Result = "result",
  RetrieveBookingConfirmation = "retrieve-booking-confirmation",
  Return = "return",
  Reward = "reward",
  Route = "route",
  Save = "save",
  Search = "search",
  Seats = "seats",
  See_1 = "see-1",
  See_2 = "see-2",
  SeeTheFerryTerms = "see-the-ferry-terms",
  SeeThePartneringAgencies = "see-the-partnering-agencies",
  See = "see",
  Send = "send",
  SettingGear = "setting-gear",
  Share = "share",
  Shops = "shops",
  SignInOrRegister = "sign-in-or-register",
  SignOut = "sign-out",
  SingleSeat = "single-seat",
  SingleTrip = "single-trip",
  SortBy = "sort-by",
  Star = "star",
  Start = "start",
  StrikesV1 = "strikes-v1",
  StrikesV2 = "strikes-v2",
  SupportClosed = "support-closed",
  TermsOfUse = "terms-of-use",
  ThumbsRating = "thumbs-rating",
  TicketNew = "ticket-new",
  TicketOffers = "ticket-offers",
  Ticket = "ticket",
  ToDoList = "to-do-list",
  Toolkit = "toolkit",
  TopSpeed = "top-speed",
  Track = "track",
  TrailerSideView = "trailer-side-view",
  Trailer = "trailer",
  TripCancellation = "trip-cancellation",
  UpArrow = "up-arrow",
  VehCap = "veh-cap",
  VehicleCapacity = "vehicle-capacity",
  VehicleV2 = "vehicle-v2",
  Vehicle = "vehicle",
  Vehicles = "vehicles",
  Verify = "verify",
  VesselType = "vessel-type",
  Visa = "visa",
  VoucherRefund = "voucher-refund",
  Voucher = "voucher",
  Wallet = "wallet",
  Warning = "warning",
  WeatherDisruptionsV1 = "weather-disruptions-v1",
  WeatherDisruptionsV2 = "weather-disruptions-v2",
  Width = "width",
  Wifi = "wifi",
  Worldwide = "worldwide",
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.Add]: "61697",
  [Icons.Agent]: "61698",
  [Icons.AirConditioning]: "61699",
  [Icons.Airport]: "61700",
  [Icons.Alacarte]: "61701",
  [Icons.Apple]: "61702",
  [Icons.AreaV2]: "61703",
  [Icons.Area]: "61704",
  [Icons.ArrowBack]: "61705",
  [Icons.ArrowDown]: "61706",
  [Icons.ArrowForward]: "61707",
  [Icons.ArrowGroup]: "61708",
  [Icons.Arrowdown]: "61709",
  [Icons.BankRefund]: "61710",
  [Icons.Bar]: "61711",
  [Icons.BarcodeDisable]: "61712",
  [Icons.BedCabin]: "61713",
  [Icons.Bed]: "61714",
  [Icons.Bicycle]: "61715",
  [Icons.Bin]: "61716",
  [Icons.BoardingCard]: "61717",
  [Icons.Book]: "61718",
  [Icons.Bookmark]: "61719",
  [Icons.BuildCountry]: "61720",
  [Icons.BuildYear]: "61721",
  [Icons.Bus]: "61722",
  [Icons.Cabins]: "61723",
  [Icons.Calendar]: "61724",
  [Icons.Calender]: "61725",
  [Icons.Call]: "61726",
  [Icons.CamperSideView]: "61727",
  [Icons.Camper]: "61728",
  [Icons.Captain]: "61729",
  [Icons.CarSideView]: "61730",
  [Icons.CardDisable]: "61731",
  [Icons.CharterIn]: "61732",
  [Icons.CharterOut]: "61733",
  [Icons.Check]: "61734",
  [Icons.Checkboxoff]: "61735",
  [Icons.Checkboxon]: "61736",
  [Icons.CheckinV2]: "61737",
  [Icons.CheckinV3]: "61738",
  [Icons.Checkin]: "61739",
  [Icons.Checklist]: "61740",
  [Icons.ChevronDown]: "61741",
  [Icons.ChevronLeft]: "61742",
  [Icons.ChevronRight]: "61743",
  [Icons.ChevronUp]: "61744",
  [Icons.Clock]: "61745",
  [Icons.Close]: "61746",
  [Icons.CompanyContactInformation_2]: "61747",
  [Icons.CompanyContactInformation]: "61748",
  [Icons.Complete]: "61749",
  [Icons.Contacts]: "61750",
  [Icons.ContractComplete]: "61751",
  [Icons.ContractEdit]: "61752",
  [Icons.ConvertToOpen]: "61753",
  [Icons.Copy]: "61754",
  [Icons.Courier]: "61755",
  [Icons.CovidV1]: "61756",
  [Icons.CovidV2]: "61757",
  [Icons.CreateASupportRequest]: "61758",
  [Icons.CruiseSpeed]: "61759",
  [Icons.CustomerSupportV2]: "61760",
  [Icons.CustomerSupport]: "61761",
  [Icons.Deck]: "61762",
  [Icons.Departure]: "61763",
  [Icons.Details]: "61764",
  [Icons.Direct]: "61765",
  [Icons.DisableFerry]: "61766",
  [Icons.DisableOnlineCheckIn]: "61767",
  [Icons.Disconnected]: "61768",
  [Icons.Docked]: "61769",
  [Icons.Dog]: "61770",
  [Icons.DownloadAReceipt]: "61771",
  [Icons.DownloadPdf]: "61772",
  [Icons.Drink]: "61773",
  [Icons.ETicket]: "61774",
  [Icons.ETickets]: "61775",
  [Icons.EconomyCabins]: "61776",
  [Icons.Edit]: "61777",
  [Icons.Email]: "61778",
  [Icons.Error]: "61779",
  [Icons.Euro]: "61780",
  [Icons.ExtraGeneral]: "61781",
  [Icons.ExtraV2]: "61782",
  [Icons.Extra]: "61783",
  [Icons.FavoriteDisable]: "61784",
  [Icons.Favorite]: "61785",
  [Icons.Feedback]: "61786",
  [Icons.FerryTrack]: "61787",
  [Icons.Ferry]: "61788",
  [Icons.File]: "61789",
  [Icons.Filter]: "61790",
  [Icons.Finish]: "61791",
  [Icons.Food]: "61792",
  [Icons.GetABookingConfirmation]: "61793",
  [Icons.Google]: "61794",
  [Icons.HavePapersReady]: "61795",
  [Icons.Hide]: "61796",
  [Icons.History]: "61797",
  [Icons.Home]: "61798",
  [Icons.Hotel]: "61799",
  [Icons.IdCard]: "61800",
  [Icons.Image]: "61801",
  [Icons.Insurance]: "61802",
  [Icons.Invoice]: "61803",
  [Icons.Language]: "61804",
  [Icons.Late]: "61805",
  [Icons.Leaf]: "61806",
  [Icons.Length]: "61807",
  [Icons.Link]: "61808",
  [Icons.LiveTracking]: "61809",
  [Icons.LocationPin]: "61810",
  [Icons.Lock]: "61811",
  [Icons.Lounge]: "61812",
  [Icons.LuggageV2]: "61813",
  [Icons.Luggage]: "61814",
  [Icons.LuxuryCabin]: "61815",
  [Icons.Mastercard]: "61816",
  [Icons.Meal]: "61817",
  [Icons.Message]: "61818",
  [Icons.Middle]: "61819",
  [Icons.MinibusSideView]: "61820",
  [Icons.Minibus]: "61821",
  [Icons.Mobile]: "61822",
  [Icons.ModifyOrCancelMyBooking]: "61823",
  [Icons.MotorcycleSideView]: "61824",
  [Icons.Motorcycle]: "61825",
  [Icons.Move]: "61826",
  [Icons.MultiTrip]: "61827",
  [Icons.NaturalDisasterV1]: "61828",
  [Icons.NaturalDisasterV2]: "61829",
  [Icons.Navigation]: "61830",
  [Icons.NoBed]: "61831",
  [Icons.NoDetails]: "61832",
  [Icons.NoETicket]: "61833",
  [Icons.NoInternet]: "61834",
  [Icons.NoVehicle]: "61835",
  [Icons.NonAmendable]: "61836",
  [Icons.NonCharterIn]: "61837",
  [Icons.NonCharterOut]: "61838",
  [Icons.NonRefundable]: "61839",
  [Icons.Notice]: "61840",
  [Icons.Notification]: "61841",
  [Icons.NumberedLocationPin]: "61842",
  [Icons.NumberedSeats]: "61843",
  [Icons.Office]: "61844",
  [Icons.OnboardFacilitiesV2]: "61845",
  [Icons.OnboardFacilities]: "61846",
  [Icons.OnlineCheckiIn]: "61847",
  [Icons.Other]: "61848",
  [Icons.Paint]: "61849",
  [Icons.PassangerCapacity]: "61850",
  [Icons.PassangerV2]: "61851",
  [Icons.Passanger]: "61852",
  [Icons.Payment]: "61853",
  [Icons.Pdf]: "61854",
  [Icons.PendingTransaction]: "61855",
  [Icons.People]: "61856",
  [Icons.PetCabin]: "61857",
  [Icons.PetCabins]: "61858",
  [Icons.PetDisable]: "61859",
  [Icons.PetInfo]: "61860",
  [Icons.PetPaw]: "61861",
  [Icons.PetsAllowed]: "61862",
  [Icons.PinDisableV2]: "61863",
  [Icons.PinDisable]: "61864",
  [Icons.PinV2]: "61865",
  [Icons.Pin]: "61866",
  [Icons.Plus]: "61867",
  [Icons.PopularRoutes]: "61868",
  [Icons.Price]: "61869",
  [Icons.PrintTicket]: "61870",
  [Icons.QrCode]: "61871",
  [Icons.QuestionMark]: "61872",
  [Icons.Railway]: "61873",
  [Icons.Rating]: "61874",
  [Icons.Receipt]: "61875",
  [Icons.ReceiveABankRefund]: "61876",
  [Icons.ReceiveACompanyInvoice]: "61877",
  [Icons.ReceiveAFullVoucher]: "61878",
  [Icons.ReceiveAReceipt]: "61879",
  [Icons.Refresh]: "61880",
  [Icons.RefurbCountry]: "61881",
  [Icons.RefurbYear]: "61882",
  [Icons.Result]: "61883",
  [Icons.RetrieveBookingConfirmation]: "61884",
  [Icons.Return]: "61885",
  [Icons.Reward]: "61886",
  [Icons.Route]: "61887",
  [Icons.Save]: "61888",
  [Icons.Search]: "61889",
  [Icons.Seats]: "61890",
  [Icons.See_1]: "61891",
  [Icons.See_2]: "61892",
  [Icons.SeeTheFerryTerms]: "61893",
  [Icons.SeeThePartneringAgencies]: "61894",
  [Icons.See]: "61895",
  [Icons.Send]: "61896",
  [Icons.SettingGear]: "61897",
  [Icons.Share]: "61898",
  [Icons.Shops]: "61899",
  [Icons.SignInOrRegister]: "61900",
  [Icons.SignOut]: "61901",
  [Icons.SingleSeat]: "61902",
  [Icons.SingleTrip]: "61903",
  [Icons.SortBy]: "61904",
  [Icons.Star]: "61905",
  [Icons.Start]: "61906",
  [Icons.StrikesV1]: "61907",
  [Icons.StrikesV2]: "61908",
  [Icons.SupportClosed]: "61909",
  [Icons.TermsOfUse]: "61910",
  [Icons.ThumbsRating]: "61911",
  [Icons.TicketNew]: "61912",
  [Icons.TicketOffers]: "61913",
  [Icons.Ticket]: "61914",
  [Icons.ToDoList]: "61915",
  [Icons.Toolkit]: "61916",
  [Icons.TopSpeed]: "61917",
  [Icons.Track]: "61918",
  [Icons.TrailerSideView]: "61919",
  [Icons.Trailer]: "61920",
  [Icons.TripCancellation]: "61921",
  [Icons.UpArrow]: "61922",
  [Icons.VehCap]: "61923",
  [Icons.VehicleCapacity]: "61924",
  [Icons.VehicleV2]: "61925",
  [Icons.Vehicle]: "61926",
  [Icons.Vehicles]: "61927",
  [Icons.Verify]: "61928",
  [Icons.VesselType]: "61929",
  [Icons.Visa]: "61930",
  [Icons.VoucherRefund]: "61931",
  [Icons.Voucher]: "61932",
  [Icons.Wallet]: "61933",
  [Icons.Warning]: "61934",
  [Icons.WeatherDisruptionsV1]: "61935",
  [Icons.WeatherDisruptionsV2]: "61936",
  [Icons.Width]: "61937",
  [Icons.Wifi]: "61938",
  [Icons.Worldwide]: "61939",
};

import { translate } from "../../../lang/lang";
import type { FormBuilderInputProps } from "../Form/FormBuilder/FormBuilder.types";
import { useInputForm } from "../Inputs.utils";
import { isValidColor } from "./ColorPicker.utils";

export const useColorPickerForm = (
  props: FormBuilderInputProps<string, "color-picker">
) => {
  return useInputForm({
    ...props,
    test: (color) => isValidColor(color),
    errorFeedback: translate("app.inputs.invalidColor"),
  });
};

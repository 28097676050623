import { ReactNode } from "react";
import type { ModalCreateOptions } from "./Modal.types";

class ModalSelector {
  selectModalBasedOnOptions: (
    modal: ReactNode,
    closeModal: (_key: string) => void,
    { key, type }: ModalCreateOptions
  ) => JSX.Element;
}

export const modalSelector = new ModalSelector();

import React, { useRef } from "react";
import { Platform, Pressable, StyleSheet } from "react-native";
import { translate } from "../../lang/lang";
import {
  AppTheme,
  ColorCategory,
  ColorKeys,
  getColorFromProvidedTheme,
  useAppTheme,
} from "../../theme";
import { Icon } from "../Icon/Icon";
import { LoadingIndicator } from "../Loading";
import { HStack } from "../Stack";
import { Text } from "../Text";
import { View } from "../View";
import type {
  ButtonInteractionState,
  ButtonProps,
  ButtonSize,
  ButtonVariant,
} from "./Button.types";

import {
  getButtonBackgroundColor,
  getBorderColor,
  getTextColorKey,
  buttonHeight,
} from "./Button.utils";

const styles = StyleSheet.create<any>({
  container: (fitToContent: boolean, size: ButtonSize = "regular") => ({
    height: buttonHeight[size],
    flexDirection: "row",
    // For horizontal container
    flexGrow: fitToContent ? 0 : 1,
    // Don't let the container stretch inside {flex: 1} vertical container
    maxHeight: buttonHeight[size],
  }),
  buttonTouchable: ({
    theme,
    fitToContent,
    color,
    variant,
    disabled,
    hovered,
    size = "regular",
  }: {
    theme: AppTheme;
    fitToContent?: boolean;
    color: ColorCategory;
    variant: ButtonVariant;
    disabled?: boolean;
    size?: ButtonSize;
    hovered?: boolean;
  }) => ({
    height: buttonHeight[size],
    borderRadius: theme.components.button.borderRadius,
    backgroundColor: getButtonBackgroundColor({
      theme,
      color,
      variant,
      disabled,
      hovered,
    }),
    borderColor: getBorderColor({ theme, color, variant, disabled, hovered }),
    borderWidth: variant === "outline" ? 1 : 0,
    flexGrow: fitToContent ? 0 : 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: theme.spacing["4"],
  }),
  buttonText: (color: ColorKeys, theme: AppTheme) => [
    {
      color: getColorFromProvidedTheme(color, theme),
    },
    Platform.select({
      ios: {
        margin: 14,
      },
    }),
  ],
});

export function ButtonBase({
  variant: variantProp = "contained",
  color = "primary",
  disabled: parentDisabled,
  onPress,
  text,
  fitToContent,
  iconStart,
  iconEnd,
  style,
  isLoading,
  loadingText,
  children,
  size = "regular",
}: ButtonProps) {
  const theme = useAppTheme();
  const disabled = parentDisabled || isLoading;
  const variant: ButtonVariant = isLoading ? "contained" : variantProp;
  const textColorKey = getTextColorKey(color, variant, disabled);
  const widthRef = useRef<number | undefined>();
  const { buttonTouchable, buttonText } = styles;
  return (
    <Pressable
      accessibilityRole="button"
      style={({ pressed, hovered }: ButtonInteractionState) => {
        return [
          buttonTouchable({
            theme,
            fitToContent,
            color,
            variant,
            disabled,
            size,
            hovered,
          }),
          { opacity: pressed ? 0.8 : 1 },
          isLoading &&
            typeof widthRef.current === "number" && {
              minWidth: widthRef.current,
              maxWidth: widthRef.current,
            },
          style,
        ];
      }}
      onPress={onPress}
      disabled={disabled}
      onLayout={(ev) => {
        if (widthRef.current === undefined && !isLoading) {
          widthRef.current = ev.nativeEvent.layout.width;
        }
      }}
    >
      <HStack style={{ position: "absolute" }}>
        {isLoading ? (
          <>
            <LoadingIndicator
              // size={size === "regular" ? 20 : 16}
              style={[
                loadingText && {
                  right: theme.spacing[2],
                },
              ]}
            />
            {loadingText ? (
              <Text
                style={buttonText(textColorKey, theme)}
                type="body2Medium"
                text={loadingText}
                selectable={false}
              />
            ) : null}
          </>
        ) : null}
      </HStack>

      <HStack style={[isLoading && { opacity: 0 }]}>
        {iconStart && (
          <Icon
            size="medium"
            color={textColorKey}
            icon={iconStart}
            iconStyle={{ marginRight: theme.spacing[2] }}
          />
        )}
        <Text
          style={buttonText(textColorKey, theme)}
          type="body2Medium"
          selectable={false}
        >
          {children || translate(text!)}
        </Text>
        {iconEnd && (
          <Icon
            size="medium"
            color={textColorKey}
            icon={iconEnd}
            iconStyle={{ marginLeft: theme.spacing[2] }}
          />
        )}
      </HStack>
    </Pressable>
  );
}

export function Button(props: ButtonProps) {
  const { fitToContent, ...otherProps } = props;
  return (
    <View
      style={[
        styles.container(!!fitToContent, props.size),
        // style
      ]}
    >
      <ButtonBase {...otherProps} fitToContent={fitToContent} />
    </View>
  );
}

import { dayjs } from "../../utils/utils.date";
import type {
  CalenderData,
  CalenderManagerProps,
  CalenderMarkedDateOptions,
  CalenderMarkedDates,
  ChildSetState,
  ChildSetStateList,
  GenericObject,
  HeaderOptions,
  OnDateChange,
  SelectedHeaderItem,
} from "./CalenderManager.types";
import {
  areMarkedDatesTheSame,
  convertToArrayChunks,
  getDaysInAMonthList,
  getMarkedDates,
} from "./CalenderManager.utils";

export class CalenderManager {
  private _dateFormat: string;
  private _minDate: string;
  private _maxDate: string;
  private _numberOfSelectiveDates: number;
  private _childSetStates: ChildSetStateList;
  private _selectedDates: dayjs.Dayjs[];
  private _markedDates: CalenderMarkedDates;
  private _selectedHeaderIndex: number;
  private _selectedHeaders: SelectedHeaderItem<GenericObject>[];
  private _currentMonthIndexes: dayjs.Dayjs[];
  private _monthSections: CalenderData;
  private _headerSetState: ChildSetState;
  private _onDateChange?: OnDateChange;
  constructor(props: CalenderManagerProps) {
    this._numberOfSelectiveDates = props.numberOfSelectiveDates;
    this._markedDates = props.initialMarkedDates || {};
    this._dateFormat = props.dateFormat;
    this._selectedDates = (props.initialSelectedDates || []).map((item) =>
      dayjs(item, props.dateFormat)
    );
    this._childSetStates = [];
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._headerSetState = () => {};
    this._selectedHeaderIndex = props.initialSelectedHeaderIndex || 0;
    this._maxDate = props.maxDate;
    this._minDate = props.minDate;
    this._selectedHeaders = props.initialSelectedHeaders || [];
    this._currentMonthIndexes = [];
    this._monthSections = [];
    this._onDateChange = props.onDateChange;
  }

  getDateFormat() {
    return this._dateFormat;
  }

  getSelectedIndex = () => this._selectedHeaderIndex;

  setCurrentViewableMonthDates = (indexes: number[]) => {
    this._currentMonthIndexes = indexes.map(
      (monthIndex) => this._monthSections[monthIndex].monthDate
    );
    this._createMarkedDates(this._selectedDates);
  };

  private _createMarkedDates = (newDates: dayjs.Dayjs[]) => {
    const newMarkedDates = getMarkedDates(
      newDates,
      this._dateFormat,
      this._currentMonthIndexes
    );
    this._selectedDates = newDates;
    this._selectedHeaderIndex = Math.max(this._selectedDates.length - 1, 0);

    this._headerSetState<HeaderOptions>({
      selectedIndex: this._selectedHeaderIndex,
      selectedDates: newDates.map((item) => item.toJSON()),
      selectedHeaders: this._selectedHeaders,
      dateNumbers: this.getDateNumbers(newDates),
    });

    for (const child of this._childSetStates) {
      const previousState = this._markedDates[child.key] as
        | CalenderMarkedDateOptions
        | undefined;
      const newState = newMarkedDates[child.key] as
        | CalenderMarkedDateOptions
        | undefined;
      const hasChanged = !areMarkedDatesTheSame(previousState, newState);
      if (hasChanged) {
        child.setState<CalenderMarkedDateOptions>(newState || {});
      }
    }
    this._markedDates = newMarkedDates;
    this._onDateChange?.({
      selectedDates: this._selectedDates,
      selectedHeaderIndex: this._selectedHeaderIndex,
      markedDates: this._markedDates,
      selectedHeaders: this._selectedHeaders,
    });
  };

  getFormattedSelectedDates() {
    return this._selectedDates.map((d) => d.format(this._dateFormat));
  }

  getSelectedDates = () => {
    return this._selectedDates;
  };
  getDateNumbers = (newDates: dayjs.Dayjs[]) =>
    newDates.map((item) => `${item.date()}`);
  getSelectiveHeaders = () => this._selectedHeaders;

  getCalenderData = (): CalenderData => {
    const maxDate = dayjs(this._maxDate, this._dateFormat);
    const minDate = dayjs(this._minDate, this._dateFormat);
    const monthsDiff = maxDate.diff(minDate, "months");
    const monthSections: CalenderData = [];
    for (let index = 0; index < monthsDiff; index++) {
      const monthDate = minDate.add(index, "month");
      const daysInMonth = getDaysInAMonthList({
        monthDate,
        dateFormat: this._dateFormat,
      });
      const daysInMonthSplitIntoWeeks = convertToArrayChunks(daysInMonth, 7);
      monthSections.push({
        monthDate,
        monthItem: daysInMonthSplitIntoWeeks,
      });
    }
    this._monthSections = monthSections;
    return monthSections;
  };

  registerChildSetState = (key: string, setState: ChildSetState) => {
    this.deregisterChildSetState(key, setState);
    this._childSetStates.push({ key, setState });
  };

  deregisterChildSetState = (key: string, setState: ChildSetState) => {
    const i = this._childSetStates.findIndex(
      (x) => x.key === key && x.setState === setState
    );
    if (i > -1) {
      this._childSetStates.splice(i, 1);
      this._childSetStates = [...this._childSetStates];
    }
  };

  registerHeaderSetState = (setState: ChildSetState) => {
    this._headerSetState = setState;
  };

  deregisterHeaderSetState = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._headerSetState = () => {};
  };

  /**
   * @param dateString YYYY-MM-DD
   */
  onDateSelect = (dateString: string) => {
    const preNewDate = dayjs(dateString, this._dateFormat);
    const newDateFormatted = preNewDate.format(this._dateFormat);
    const newDate = dayjs(newDateFormatted, this._dateFormat);

    if (this._reselectCurrentDate !== undefined) {
      const clonedDates = [...this._selectedDates];
      clonedDates[this._reselectCurrentDate] = newDate;
      this._createMarkedDates(clonedDates);
      this._reselectCurrentDate = undefined;
      return;
    }

    if (this._selectedDates.length === 0) {
      this._createMarkedDates([newDate]);
      return;
    }

    let newDates = [] as dayjs.Dayjs[];
    for (let index = 0; index < this._selectedDates.length; index++) {
      const currentDate = this._selectedDates[index];
      const nextDate = this._selectedDates[index + 1] as
        | dayjs.Dayjs
        | undefined;
      const currentDateFormatted = currentDate.format(this._dateFormat);

      // If newDated selected is the same
      if (currentDateFormatted === newDateFormatted) {
        if (
          nextDate === undefined &&
          this._numberOfSelectiveDates > this._selectedDates.length
        ) {
          newDates = [...this._selectedDates, newDate];
          break;
        }
      }

      // If new date is before the next date
      if (newDate.isBefore(dayjs(currentDateFormatted, this._dateFormat))) {
        /**
         * Remove all dates above and equal to index
         */
        newDates = [...this._selectedDates.slice(0, index), newDate];
        break;
      }

      // If this is the last of item in loop
      if (nextDate === undefined) {
        // Replace the last item
        if (this._numberOfSelectiveDates === this._selectedDates.length) {
          /**
           * Remove all dates above and equal to index and add new date
           */
          newDates = [...this._selectedDates.slice(0, index), newDate];
        } else {
          //  OR  we add new date to date list
          /**
           * add new date
           */
          newDates = [...this._selectedDates, newDate];
        }

        break;
      }

      const nextDateFormatted = nextDate.format(this._dateFormat);

      // if new date is between the prev date & next date
      if (
        newDate.isAfter(dayjs(currentDateFormatted, this._dateFormat)) &&
        newDate.isBefore(dayjs(nextDateFormatted, this._dateFormat))
      ) {
        /**
         * Remove all dates above and equal to index
         */
        newDates = [...this._selectedDates.slice(0, index + 1), newDate];
        break;
      }
    }
    this._createMarkedDates(newDates);
  };

  isBeforeToday = (dateString: string) =>
    dayjs(dateString, this._dateFormat).isBefore(dayjs());

  getCurrentHeader = () => this._selectedHeaders[this._selectedHeaderIndex];

  getHeaderFromMarkedDateOptions = (
    dateOptions: CalenderMarkedDateOptions
  ): SelectedHeaderItem<GenericObject> | undefined => {
    if (!dateOptions.selectedIndexes) {
      return;
    }

    if (dateOptions.currentIndex !== undefined) {
      return this._selectedHeaders[dateOptions.currentIndex];
    }
  };

  private _reselectCurrentDate = undefined as number | undefined;
  setSelectedIndex = (newIndex: number) => {
    if (newIndex <= this._selectedHeaderIndex) {
      this._reselectCurrentDate = newIndex;
      this._createMarkedDates(this._selectedDates.slice(0, newIndex));
    }
  };

  setSelectedHeaders = (
    selectedHeaders: SelectedHeaderItem<GenericObject>[],
    newIndex?: number
  ) => {
    this._selectedHeaders = selectedHeaders;
    this._numberOfSelectiveDates = this._selectedHeaders.length - 1;
    if (newIndex !== undefined) {
      this._createMarkedDates(this._selectedDates.slice(0, newIndex + 1));
    } else {
      this._createMarkedDates(this._selectedDates);
    }
  };

  clearDates = () => {
    this._createMarkedDates([]);
  };
}

import { useAppTheme } from "../../theme";
import type { ChildrenProp } from "../../types/components";
import { IconButton } from "../Button/Button.Icon";
import { Divider } from "../Divider/Divider";
import { HStack, StackProps, VStack } from "../Stack";
import { Text } from "../Text";
import { View } from "../View";

export interface ModalHeaderProps extends ChildrenProp {
  onPress?: () => void;
  title: string;
  subtitle?: string;
  divider?: boolean;
}

export const ModalHeaderContainer = (props: StackProps) => {
  const modalHeaderProps = useAppTheme().components.modalHeader;
  return (
    <HStack
      style={[{ alignSelf: "stretch" }, props.style]}
      {...modalHeaderProps.container}
      {...props}
    />
  );
};

export const ModalHeader = ({
  onPress,
  title,
  subtitle,
  children,
  divider,
}: ModalHeaderProps) => {
  const modalHeaderProps = useAppTheme().components.modalHeader;
  return (
    <>
      <ModalHeaderContainer>
        <IconButton icon="close" color="monochrome" onPress={onPress} />
        <VStack space="1" flex={1}>
          <Text.Body1Medium {...modalHeaderProps.title}>
            {title}
          </Text.Body1Medium>
          {subtitle ? (
            <Text.Body2Regular {...modalHeaderProps.subtitle} numberOfLines={1}>
              {subtitle}
            </Text.Body2Regular>
          ) : null}
        </VStack>
        {children}
      </ModalHeaderContainer>
      {divider ? <Divider /> : <View />}
    </>
  );
};

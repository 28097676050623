import { isWeb } from "../utils/utils.helper";
import type { AppTheme, Palette, Spacing, Typography } from "./Theme.types";

export const palette: Palette = {
  primary: {
    base: "#F7FAFD",
    extraLight: "#BED7F2",
    light: "#72A8E3",
    mid: "#1A72D1",
    dark: "#004998",
    extraDark: "#003A79",
  },
  secondary: {
    base: "#FFF9F1",
    extraLight: "#FFCA84",
    light: "#EA8F18",
    mid: "#A4631B",
    dark: "#6C4118",
    extraDark: "#553316",
  },
  error: {
    base: "#FFF9F6",
    extraLight: "#FDC9B3",
    light: "#FC8250",
    mid: "#CF3F04",
    dark: "#892A03",
    extraDark: "#6D2102",
  },
  success: {
    base: "#F5FCF2",
    extraLight: "#A9E48D",
    light: "#6AB744",
    mid: "#4A7F2F",
    dark: "#30531F",
    extraDark: "#264219",
  },
  monochrome: {
    base: "#FAFAFA",
    extraLight: "#D2D4DB",
    light: "#9FA4B1",
    mid: "#697285",
    dark: "#545F74",
    extraDark: "#353B47",
  },
  backdrop: "#353B47",
  white: "#FFFFFF",
  black: "#000000",
};

export const typography = (ratio = 1, margin = 0): Typography => ({
  heading1: {
    fontFamily: "heading1",
    fontSize: 48 * ratio + margin,
    lineHeight: 58,
    letterSpacing: 0.02,
  },
  heading2: {
    fontFamily: "heading2",
    fontSize: 40 * ratio + margin,
    lineHeight: 60,
    letterSpacing: 0.02,
  },
  heading3: {
    fontFamily: "heading3",
    fontSize: 24 * ratio + margin,
    lineHeight: 36,
    letterSpacing: 0.01,
  },
  sectionTitle: {
    fontFamily: "sectionTitle",
    fontSize: 20 * ratio + margin,
    lineHeight: 30,
    letterSpacing: 0.02,
  },
  body1SemiBold: {
    fontFamily: "body1SemiBold",
    fontSize: 16 * ratio + margin,
    lineHeight: 24,
    letterSpacing: 0.02,
  },
  body1Medium: {
    fontFamily: "body1Medium",
    fontSize: 16 * ratio + margin,
    lineHeight: 24,
    letterSpacing: 0.02,
  },
  body1Regular: {
    fontFamily: "body1Regular",
    fontSize: 16 * ratio + margin,
    lineHeight: 24,
    letterSpacing: 0.02,
  },
  body2Medium: {
    fontFamily: "body2Medium",
    fontSize: 14 * ratio + margin,
    lineHeight: 21,
    letterSpacing: 0.02,
  },
  body2Regular: {
    fontFamily: "body2Regular",
    fontSize: 14 * ratio + margin,
    lineHeight: 21,
    letterSpacing: 0.02,
  },
  small: {
    fontFamily: "small",
    fontSize: 12 * ratio + margin,
    lineHeight: 18,
    letterSpacing: 0.02,
  },
});

export const spacing: Spacing = {
  "0": 0,
  px: 1,
  "0.5": 2,
  "1": 4,
  "1.5": 6,
  "2": 8,
  "2.5": 10,
  "3": 12,
  "4": 16,
  "5": 20,
  "6": 24,
  "7": 28,
  "8": 32,
  "9": 36,
  "10": 40,
  "11": 44,
  "12": 48,
};

const borderRadius = 12;
export const inputHeight = isWeb ? 44 : 60;

export const defaultTheme: AppTheme = {
  palette,
  typography: typography(),
  spacing,
  components: {
    button: {
      borderRadius: borderRadius,
    },
    modalHeader: {
      container: {
        space: "3",
        align: "center",
        p: "3",
      },
      title: {},
      subtitle: {
        color: "monochrome-mid",
      },
    },
    modalBody: {
      p: "3",
    },
    modalFooter: {
      container: {
        p: "3",
        space: "3",
        align: "center",
      },
      confirm: {
        variant: "contained",
        color: "primary",
        fitToContent: true,
      },
      cancel: {
        variant: "text",
        color: "monochrome",
        fitToContent: true,
      },
    },
    modalPopup: {
      borderRadius: borderRadius,
      backgroundColor: palette.white,
      marginHorizontal: spacing[3],
      overflow: "hidden",
      ...(isWeb
        ? {
            minWidth: 450,
            alignSelf: "center",
          }
        : {}),
    },
    modalActionSheet: {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      backgroundColor: palette.white,
      padding: spacing[3],
    },
    modalFullActionSheet: {
      backgroundColor: palette.white,
    },
    textInput: {
      height: inputHeight,
      paddingLeft: spacing[4],
      paddingRight: spacing[4],
      borderWidth: 1,
      flexDirection: "row",
      alignItems: "center",
      borderColor: palette.monochrome.extraLight,
      borderRadius: borderRadius,
    },

    formInputLabel: {},

    buttonInput: {
      borderRadius: borderRadius,
      minHeight: inputHeight,
      padding: spacing[3],
      borderColor: palette.monochrome.extraLight,
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      borderWidth: 1,
    },

    multiSelectItem: {
      paddingVertical: spacing[1],
      paddingHorizontal: spacing[4],
      backgroundColor: palette.primary.base,
      borderColor: palette.primary.light,
      borderWidth: 1,
      borderRadius: 999,
      color: palette.primary.mid,
    },
  },
};

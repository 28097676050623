import { useEffect, useRef, useState } from "react";

import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";

import { useAppTheme } from "../../theme";
import { getKeysOfObject } from "../../utils/utils.helper";
import { IconButton } from "../Button/Button.Icon";
import { BaseInput, BaseInputProps } from "../Inputs/BaseInputs/BaseInput";
import { AxiosBaseQuery } from "../../utils/network";
import { translate } from "../../lang/lang";
import { ViewStyle } from "../../types/components";

interface SearchProps<T> {
  keyExtractor?: (item: T) => string;
  searchTextExtractor?: (item: T) => string;
  onSearch: (filteredData: T[], ids?: string[]) => void;
  itemsToIgnore?: (item: T) => boolean;
  data: T[];
  keysToIgnore?: (keyof T)[];
  thresholdDelay?: number;
  searchChars?: { and: string; or: string };
  deps?: any[];
  isClearable?: boolean;
}

export interface SearchStateProps<U = void> {
  arg?: U | void;
  keyToList?: string;
  hook: UseQuery<QueryDefinition<any, AxiosBaseQuery, any, any, "rootApi">>;
  style?: ViewStyle;
}

export const useSearchState = <T, U = void>({
  hook,
  arg,
  keyToList,
  style,
}: SearchStateProps<U>) => {
  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } =
    hook(arg);
  const [stateData, setStateData] = useState<T[]>([]);

  if (data && !Array.isArray(data) && !keyToList) {
    throw new Error(
      'You must put a key to point to the list you want search for using "keyToList" in: ' +
        Object.keys(data).join(",")
    );
  }
  const searchData = (data ? (keyToList ? data[keyToList] : data) : []) as T[];
  const Search = (
    <SearchBar
      inputContainerStyle={style}
      data={searchData}
      deps={[searchData.length !== stateData.length, isFetching]}
      onSearch={(filteredData) => {
        setStateData(filteredData);
      }}
    />
  );

  return {
    data,
    list: stateData,
    isLoading,
    isFetching,
    error,
    refetch,
    isError,
    isSuccess,
    Search,
  };
};

export function SearchBar<T>({
  data,
  deps,
  keyExtractor,
  searchTextExtractor,
  onSearch,
  itemsToIgnore,
  keysToIgnore,
  thresholdDelay = 350,
  isClearable = true,
  searchChars = { and: ",", or: ";" },
  ...props
}: SearchProps<T> & BaseInputProps) {
  const [text, setText] = useState<string>("");
  const { spacing } = useAppTheme();
  const ref = useRef(0);
  const onChange = (newText: string) => {
    if (!newText) {
      return onSearch(data, keyExtractor ? data.map(keyExtractor) : undefined);
    }

    const filteredData = data.filter((item) => {
      if (itemsToIgnore && itemsToIgnore(item)) {
        return true;
      }

      const indexableText = searchDefault(
        item,
        keysToIgnore,
        searchTextExtractor
      );

      // ['123', '456;5785;3455', '1233', '456;345'] or ['456;5785;3455']
      const andTexts = newText
        .split(searchChars.and) // ','
        .map((txt) => txt.trim().toLocaleLowerCase())
        .filter((txt) => txt !== "");

      const andCount = andTexts.reduce(
        (memo, nextAndTextWhichMayHaveAnOrChar) => {
          const orTexts = nextAndTextWhichMayHaveAnOrChar
            .split(searchChars.or)
            .map((txt) => txt.trim())
            .filter((txt) => txt !== "");

          const isOrSatisfied = orTexts.some((orText) =>
            indexableText.includes(orText)
          );

          const addition = isOrSatisfied ? 1 : 0;
          return addition + memo;
        },
        0
      );

      return andCount === andTexts.length;
    });
    onSearch(
      filteredData,
      keyExtractor ? filteredData.map(keyExtractor) : undefined
    );
  };

  useEffect(() => {
    clearTimeout(ref.current);
    ref.current = window.setTimeout(() => {
      onChange(text);
    }, thresholdDelay);
  }, [text]);

  useEffect(() => {
    clearTimeout(ref.current);
    onChange(text);
  }, deps || [data]);

  return (
    <BaseInput
      {...props}
      placeholder={translate("app.inputs.search")}
      iconStart="search"
      iconEnd={
        text && isClearable ? (
          <IconButton
            variant="plain"
            icon="close"
            color="monochrome"
            outerStyle={{ marginRight: -spacing[3] }}
            onPress={() => setText("")}
          />
        ) : undefined
      }
      inputContainerStyle={[
        {
          borderRadius: 0,
          borderWidth: 0,
        },
        props.inputContainerStyle,
      ]}
      value={text}
      onChangeText={(text) => {
        setText(text);
      }}
    />
  );
}

function searchDefault<T>(
  item: T,
  keysToIgnore: (keyof T)[] | undefined,
  searchTextExtractor: ((item: T) => string) | undefined
) {
  const keys = getKeysOfObject(item);
  return String(
    keys
      .map((key) => {
        if (keysToIgnore ? keysToIgnore.indexOf(key) !== -1 : false) return "";
        return JSON.stringify(item[key]);
      })
      .join("") + (searchTextExtractor ? searchTextExtractor(item) : "")
  ).toLowerCase();
}

import Constants from "expo-constants";
import {
  KeyboardAvoidingView,
  SafeAreaView as SafeAreaViewRN,
} from "react-native";
import { ChildrenProp } from "../../types/components";
import { isIOS } from "../../utils/utils.helper";

export const SafeAreaAppView = (props: ChildrenProp) => {
  return (
    <SafeAreaViewRN
      style={{
        flex: 1,
        marginTop: Constants.statusBarHeight,
        position: "relative",
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={isIOS ? "padding" : "height"}
      >
        {props.children}
      </KeyboardAvoidingView>
    </SafeAreaViewRN>
  );
};

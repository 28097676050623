import { Platform } from "react-native";

export const isAndroid = Platform.OS === "android";
export const isIOS = Platform.OS === "ios";
export const isWeb = Platform.OS === "web";
export const isNativeMobile = Platform.OS !== "web";

export function notEmpty<t>(value: t | null | undefined): value is t {
  return value !== null && value !== undefined;
}

export function getKeysOfObject<T>(obj: T): (keyof T)[] {
  return Object.keys(obj as any) as (keyof T)[];
}

export function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

export function isObject(obj: any) {
  return obj !== undefined && obj !== null && obj.constructor == Object;
}

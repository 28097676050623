import { useAppTheme } from "../../../theme";
import { Toggle } from "../../Toggle/Toggle";
import { BaseInput } from "../BaseInputs/BaseInput";
import { CommonInputProps } from "../Form/FormBuilder/FormBuilder.types";
import { FormInputContainer } from "../FormInputs/FormInput.Container";
import { useToggleInput } from "./ToggleInput.hook";

export interface ToggleInputProps extends CommonInputProps<boolean, "toggle"> {}

export const ToggleInput = (props: ToggleInputProps) => {
  const {
    value = false,
    setValue,
    // error,
    // feedback,
    // allowEmpty,
    disabled,
    label,
    // optional,
    readonly,
    noFeedback,
  } = useToggleInput(props);

  const { palette } = useAppTheme();

  return (
    <FormInputContainer
      noFeedback={noFeedback}
      disabled={disabled}
      focused={false}
      // feedback={feedback}
      label={label}
      optional
      readonly={readonly}
    >
      <BaseInput
        inputStyle={{
          color: value ? palette.black : palette.monochrome.mid,
          minWidth: 0,
        }}
        editable={false}
        value={label}
        iconEnd={
          <Toggle
            value={value}
            color="primary"
            size="medium"
            onChange={(nv) => setValue(nv)}
            disabled={disabled}
            readonly={props.readonly}
          />
        }
      />
    </FormInputContainer>
  );
};

import React from "react";
import { Platform, StyleProp, StyleSheet, Text, ViewStyle } from "react-native";
import Svg, { Line } from "react-native-svg";
import {
  ColorCategory,
  ColorShades,
  useAppTheme,
  useColorFromTheme,
} from "../../theme";
import { isWeb } from "../../utils/utils.helper";
import { LoadingIndicator } from "../Loading";
import { View } from "../View";
import resolveIcon from "./Icon.resolve";
import { IconBaseProps, IconSize, IconVariant } from "./Icon.types";
import { getIconBackgroundColor, getIconTextColor } from "./Icon.utils";

export type IconProps = IconBaseProps;

const ratio = isWeb ? 1 : 1.15;

const sizeToPx = {
  small: 10 * 1.5,
  medium: 20 * ratio,
  large: 24 * ratio,
};

const circleSizeToPx = {
  small: 20 * 1.5,
  medium: 40 * ratio,
  large: 40 * ratio,
};

const styles = StyleSheet.create<any>({
  iconText: (color: string, size: IconSize) => ({
    fontFamily: "icons",
    fontSize: getIconSize(size),
    color,
  }),
  iconCircle: (size: IconSize, radius: number | undefined) => ({
    width: radius ? radius * 2 : circleSizeToPx[size],
    height: radius ? radius * 2 : circleSizeToPx[size],
    borderRadius: radius || sizeToPx[size],
    // backgroundColor: color,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 0,
    padding: 0,
    paddingVertical: 0,
  }),
  container: (size: IconSize | number) => [
    {
      alignItems: "center",
      justifyContent: "center",
    },
    Platform.select({
      ios: {
        width: getIconSize(size),
        height: getIconSize(size),
      },
    }),
  ],
  icon: {
    position: "absolute",
  },
  svg: {
    position: "absolute",
  },
});

const getIconSize = (size: IconSize | number) => {
  if (typeof size === "string") {
    return sizeToPx[size];
  }
  return size;
};

export const Icon = ({
  icon,
  color = "primary-mid",
  size = "medium",
  iconStyle,
  crossed,
  customSize,
}: IconProps) => {
  const colorStr = useColorFromTheme(color);
  const iconSize = customSize || size;
  if (crossed) {
    return (
      <View style={[styles.container(iconSize), iconStyle]}>
        <Text style={styles.iconText(colorStr, iconSize)} selectable={false}>
          {resolveIcon(icon)}
        </Text>
        <Svg
          style={styles.svg}
          height={getIconSize(iconSize)}
          width={getIconSize(iconSize)}
          viewBox="0 0 24 24"
          fill="none"
          stroke={colorStr}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <Line x1="24" y1="0" x2="0" y2="24" />
        </Svg>
      </View>
    );
  } else {
    return (
      <View style={[styles.container(iconSize), iconStyle]}>
        <Text style={styles.iconText(colorStr, iconSize)} selectable={false}>
          {resolveIcon(icon)}
        </Text>
      </View>
    );
  }
};

export interface IconCircleBaseProps {
  // background?: ColorCategory | "transparent";
  radius?: number;
  outerStyle?: StyleProp<ViewStyle>;
  variant?: IconVariant;
  color?: ColorCategory;
  shade?: ColorShades;
  disabled?: boolean;
  hovered?: boolean;
  isLoading?: boolean;
}
export type IconCircleProps = Omit<IconProps, "color"> & IconCircleBaseProps;

export const IconCircle = ({
  outerStyle,
  // background,
  radius,
  variant = "plain",
  color = "primary",
  size = "medium",
  shade = "mid",
  hovered,
  isLoading,
  ...iconProps
}: IconCircleProps) => {
  const theme = useAppTheme();
  const backgroundColor = getIconBackgroundColor({
    theme,
    color,
    disabled: iconProps.disabled,
    variant,
    hovered,
  });

  const iconColor = getIconTextColor({
    color,
    disabled: iconProps.disabled,
    variant,
  });

  return (
    <View
      style={[
        styles.iconCircle(size, radius),
        {
          backgroundColor,
          // !hovered && background ? background : backgroundColor,
        },
        outerStyle,
      ]}
    >
      {isLoading ? (
        <LoadingIndicator color={iconColor} size="small" />
      ) : (
        <Icon {...iconProps} color={iconColor} size={size} />
      )}
    </View>
  );
};

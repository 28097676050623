import React, { createContext, useState } from "react";

import { defaultTheme } from "./Theme.default";
import type { AppTheme } from "./Theme.types";

export const ThemeContext = createContext({
  theme: defaultTheme,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setThemeMode: (_darkModeOn: "light" | "dark") => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: (_theme: AppTheme) => {},
});
export const AppThemeProvider = ({
  children,
  theme: newDefaultTheme,
}: {
  children: React.ReactNode;
  theme?: AppTheme;
}) => {
  (window as any).appInitiated = true;

  if (!(window as any).appInitiated) {
    throw new Error('You must call "initiateApp" before running your app');
  }

  const [theme, setTheme] = useState<AppTheme>(newDefaultTheme || defaultTheme);
  return (
    <ThemeContext.Provider
      value={{
        theme,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setThemeMode: (_darkModeOn: "light" | "dark") => {},
        setTheme: (newTheme) => {
          setTheme(newTheme);
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

import { FormGroup } from "@droplet_tech/core-common-types";
import { useEffect, useRef, useState } from "react";
import _isEqual from "lodash.isequal";
import { translate } from "../../lang/lang";
import type { FormHookProp, ShowFeedback } from "./Form/Form.types";
import { getFormInputProps } from "./Form/Form.utils";

export const isRequired = (optional: boolean | undefined) =>
  optional === undefined || optional === false;

export const extractInitialValueFromConfig = (
  formGroup: FormGroup,
  getInnerItem?: boolean
) => {
  if (formGroup.initialValue !== undefined) {
    return formGroup.initialValue;
  }

  if (formGroup.type === "toggle") {
    return false;
  }

  if (formGroup.type !== "form-item") {
    if (formGroup.allowEmpty) {
      switch (formGroup.type) {
        case "file-upload":
        case "multi-select": {
          return [];
        }
        case "color-picker":
        case "text":
        case "email":
        case "text":
        case "phone":
        case "single-select":
        case "date": {
          return "";
        }
        case "number": {
          return 0;
        }
        case "object-select": {
          return {};
        }

        default:
          return undefined;
      }
    }

    return undefined;
  }

  if (
    formGroup.type === "form-item" &&
    formGroup.valueType === "list" &&
    !getInnerItem
  ) {
    return formGroup.allowEmpty ? [] : undefined;
  }

  const initialValue: { [key: string]: any } = {};

  for (const nestedConfigI of formGroup.config) {
    if (nestedConfigI.type === "separator") {
      continue;
    }

    for (const item of nestedConfigI.items) {
      if (item.type === "form-item") {
        initialValue[item.id] = extractInitialValueFromConfig(item);
      } else {
        initialValue[item.id] = item.initialValue;
      }
    }
  }

  return initialValue;
};

export const getInitialValue = <T>(props?: FormHookProp<T, any>) => {
  if (props) {
    if (props.value !== undefined) {
      return props.value;
    }
    if (props.initialValue !== undefined) {
      return props.initialValue;
    }

    return extractInitialValueFromConfig(props.config);
  }
};

export const testInput = (props: {
  value: any;
  test: () => boolean;
  onBlur: boolean | undefined;
  optional: boolean | undefined;
  errorFeedback: string | undefined;
}): { valid: boolean; feedback: string } => {
  const { value, test, onBlur, optional, errorFeedback } = props;
  const required = isRequired(optional);

  if (value) {
    return {
      valid: test(),
      feedback: errorFeedback || translate("app.inputs.invalidInput"),
    };
  }
  if (onBlur) {
    return {
      valid: true,
      feedback: "",
    };
  }
  if (required) {
    return {
      valid: false,
      feedback: translate("app.inputs.requiredField"),
    };
  }
  return {
    valid: true,
    feedback: "",
  };
};

export const useInputForm = <T, X extends FormGroup["type"]>(
  props: FormHookProp<T, X>
) => {
  const initialValue = getInitialValue<T>(props);
  const valueRef = useRef(initialValue);
  const configRef = useRef(props.config);
  const [config, setStateConfig] = useState<Extract<FormGroup, { type: X }>>(
    props.config
  );
  const [value, setStateValue] = useState<T | undefined>(initialValue);
  const [feedback, setFeedback] = useState<string>("");
  const setValue = (newValue: T | undefined) => {
    valueRef.current = newValue;
    setStateValue(valueRef.current);
    props.form.onInputChange(props.config.id, valueRef.current);
    props.form.onParentChange(props.config.id, valueRef.current);
  };

  const setConfig = (newConfig: FormGroup) => {
    configRef.current = newConfig as any;
    setStateConfig(configRef.current);
  };

  const isValid = (option: ShowFeedback = {}) => {
    const { showFeedback = false, onBlur = false } = option;
    const testValue = props?.test;
    const { valid, feedback } = testInput({
      optional: props?.config.optional,
      errorFeedback: props?.errorFeedback,
      value: valueRef.current,
      test: testValue ? () => testValue(valueRef.current as T) : () => true,
      onBlur,
    });

    if (showFeedback) {
      if (valid) {
        setFeedback("");
      } else {
        setFeedback(feedback);
      }
    }
    return valid;
  };

  useEffect(() => {
    /**
     * Only use for form
     */
    if (props) {
      props.form.registerInput<T>({
        id: props.config.id,
        type: props.config.type,
        input: {
          getValue: () => {
            return props?.getValue
              ? props.getValue(valueRef.current)
              : valueRef.current;
          },
          setValue: (newValue) => setValue(newValue),
          isValid,
          onParentChange: (parentId, parentValue) => {
            if (parentId === props.config.id) {
              console.error(
                "Parent ID for form conditions cannot listen to itself",
                {
                  parentId,
                  parentValue,
                  configId: props.config.id,
                }
              );
              return;
            }

            const conditions = props.config.conditions;
            if (conditions) {
              const conditionFound = conditions.find((condition) => {
                return condition.values.some((v) => {
                  if (v.listenTo === parentId) {
                    if (Array.isArray(parentValue) && Array.isArray(v.value)) {
                      return _isEqual(
                        v.value.slice().sort(),
                        parentValue.slice().sort()
                      );
                    } else {
                      return _isEqual(v.value, parentValue);
                    }
                  }
                  return false;
                });
              });

              // Render new form group
              if (conditionFound) {
                setFeedback("");
                setValue(conditionFound.formGroup.initialValue);
                setConfig(conditionFound.formGroup as any);
                return;
              }
            }

            // Return to default form group
            if (props.config !== configRef.current) {
              setFeedback("");
              setValue(props.config.initialValue);
              setConfig(props.config);
            }
          },
        },
        conditions: props.config.conditions,
        rules: props.rules,
      });

      if (getInitialValue<T>(props) !== undefined) {
        const showFeedback = !((props.config as any)?.preview?.length === 0);
        if (showFeedback) {
          isValid({ showFeedback });
        }
      }

      return () => {
        props?.form.unregisterInput(props.config.id);
      };
    }
  }, []);

  useEffect(() => {
    const propValue = props?.value;
    if (propValue !== undefined && value !== propValue) {
      setValue(propValue);
    }
  }, [props?.value]);

  return {
    setValue,
    isValid,
    value,
    feedback,
    noFeedback: config.noFeedback,
    config,
    ...getFormInputProps({ ...props, feedback, config: config }),
  };
};

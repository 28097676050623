export default {
  app: {
    button: {
      continue: "Continue",
      regular: "Regular",
      large: "Large",
      small: "Small",
    },
    inputs: {
      email: "Email",
      invalidInput: "Invalid field",
      invalidEmail: "Please enter a valid email",
      invalidDate: "Date must be in format of {{format}}",
      invalidPhoneNumber: "Phone number is invalid",
      invalidNumber: "Must be a valid number",
      invalidLangText: "Field must have 'en' included",
      requiredField: "Field is required",
      editAcceptedLocale: "Edit accepted locale",
      startByAddingALocale: "Start by adding a locale",
      selectColor: "Select a color",
      invalidColor: "Invalid color",
      addLocale: "Add locale",
      englishEn: "English (en)",
      empty: "<empty>",
      items: "[Items x{{count}}]",
      cancel: "Cancel",
      confirm: "Confirm",
      save: "Save",
      done: "Done",
      clear: "Clear",
      search: "Search",
      reset: "Reset",
      invalid: "<invalid>",
      errorImage: "Error uploading image",
      couldNotUploadFile: "Error uploading file",
      noData: "No data",
    },
    modal: {
      verify: "Verify",
      areYouSure: "Do you wish to continue?",
      confirm: "Confirm",
      cancel: "Cancel",
    },
    feedback: {
      genericError: "Looks like something went wrong!",
      lessThanSize: "Must be less than {{size}}",
    },
  },
};

import { useAppTheme } from "../../../theme";
import type { ChildrenProp, ViewStyle } from "../../../types/components";
import { HStack, VStack } from "../../Stack";
import { Text } from "../../Text";

export const LabelAsterisk = () => {
  return <Text.Small color="error-dark">*</Text.Small>;
};

export const BLANK_LABEL = "@b";

export const FormInputContainer = ({
  children,
  label,
  disabled,
  error,
  feedback,
  focused,
  optional,
  readonly,
  noFeedback,
  style,
}: ChildrenProp & {
  label?: string;
  disabled?: boolean;
  error?: boolean;
  feedback?: string;
  focused?: boolean;
  optional?: boolean;
  readonly?: boolean;
  noFeedback?: boolean;
  style?: ViewStyle;
}) => {
  const { palette, components } = useAppTheme();
  const textStyle = [
    {
      color: palette.monochrome.mid,
    },
    error &&
      !focused &&
      !readonly && {
        color: palette.error.dark,
      },
    disabled &&
      !readonly && {
        color: palette.monochrome.extraLight,
      },
  ];

  return (
    <VStack space="1" style={style}>
      {label ? (
        <HStack>
          <Text.Body2Regular
            style={[
              components.formInputLabel,
              textStyle,
              label === BLANK_LABEL && { opacity: 0 },
            ]}
            numberOfLines={1}
          >
            {label}
          </Text.Body2Regular>
          {optional || disabled || readonly ? null : <LabelAsterisk />}
        </HStack>
      ) : null}
      {children}

      {noFeedback ? null : feedback && !disabled && !readonly ? (
        <Text.Body2Regular style={textStyle}>{feedback}</Text.Body2Regular>
      ) : (
        <Text.Body2Regular style={textStyle}> </Text.Body2Regular>
      )}
    </VStack>
  );
};

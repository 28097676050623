import { DelayRender, DelayRenderProps } from "../Delay/Delay";
import { Icon, IconsId } from "../Icon";
import { VStack } from "../Stack";
import { Text } from "../Text";

export interface FeedbackProps extends DelayRenderProps {
  isDataEmpty: boolean;
  minLoadingHeight?: number;
  feedback: string;
  feedbackIcon?: IconsId;
  children: React.ReactNode;
}

export const Feedback = ({
  isDataEmpty,
  feedback,
  feedbackIcon,
  children,
  minLoadingHeight = 150,
  ...delayProps
}: FeedbackProps) => {
  return (
    <DelayRender
      {...delayProps}
      containerStyle={[
        delayProps.isLoading && { minHeight: minLoadingHeight },
        delayProps.containerStyle,
      ]}
    >
      {isDataEmpty && !delayProps.isLoading ? (
        <VStack
          space="3"
          flex={1}
          align="center"
          justify="center"
          style={{ minHeight: minLoadingHeight }}
        >
          {feedbackIcon ? (
            <Icon size="large" icon={feedbackIcon} color="monochrome-light" />
          ) : null}
          <Text.Body2Medium color="monochrome-light" align="center">
            {feedback}
          </Text.Body2Medium>
        </VStack>
      ) : (
        children
      )}
    </DelayRender>
  );
};

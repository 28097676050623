import type { CommonInputProps } from "../Form/FormBuilder/FormBuilder.types";
import { FormInput } from "../FormInputs/FormInput";
import { useEmailForm } from "./EmailInput.hook";

export interface EmailInputProps extends CommonInputProps<string, "email"> {}

export const EmailInput = ({ ...formInputProps }: EmailInputProps) => {
  const {
    value,
    setValue,
    feedback,
    isValid,
    error,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
  } = useEmailForm(formInputProps);

  return (
    <FormInput
      noFeedback={noFeedback}
      autoCorrect={false}
      autoCapitalize="none"
      autoComplete="email"
      value={value}
      onChangeText={(txt) => setValue(txt)}
      feedback={feedback}
      error={error}
      disabled={disabled}
      label={label}
      optional={optional}
      readonly={readonly}
      onBlur={() => {
        isValid({ showFeedback: true, onBlur: true });
      }}
    />
  );
};

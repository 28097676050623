import { translate } from "../../../lang/lang";
import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";

export const useSelectMultiInput = (
  props: FormHookProp<string[], "multi-select">
) => {
  return useInputForm({
    ...props,
    test: (v) => {
      if (props.config.optional) {
        return true;
      }
      return !props.config.optional && v.length > 0;
    },

    errorFeedback: translate("app.inputs.requiredField"),
  });
};

export const useSelectSingleInput = (
  props: FormHookProp<string, "single-select">
) => {
  return useInputForm(props);
};

import { getAsYouType, parsePhoneNumber } from "awesome-phonenumber";

/**
 *
 * @param text phone number as string
 * @param regionCode  2-character ISO 3166-1 region code: 'SE' for Sweden, 'CH' for Switzerland, etc.
 *
 * @return formattedText - used for putting into phoneInput
 * @return value - used for final value in request to server (Not to be used in phoneInput)

 */
export const formatPhoneNumber = (
  text: string,
  regionCode?: string
): {
  formattedText: string;
  value: string | undefined;
  countryCode: number | undefined;
  valid: boolean;
} => {
  const instance = parsePhoneNumber(text, { regionCode });
  return {
    formattedText: regionCode ? getAsYouType(regionCode).reset(text) : text,
    value: instance.number?.international?.replace?.(/\s/g, ""),
    countryCode: instance.countryCode,
    valid: instance.valid,
  };
};

// 903-352-6039
// console.log(formatPhoneNumber("7593335308", "GB"));
// console.log(getCountryCodeForRegionCode("GB"));

import { translate } from "../../../lang/lang";
import { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";
import { formatPhoneNumber } from "./PhoneInput.utils";

export const usePhoneInputForm = (props: FormHookProp<string, "phone">) => {
  return useInputForm({
    ...props,
    test: (phoneNumber) => formatPhoneNumber(phoneNumber).valid,
    errorFeedback: translate("app.inputs.invalidPhoneNumber"),
  });
};

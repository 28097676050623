import { useAppTheme } from "../../theme";
import { isWeb } from "../../utils/utils.helper";
import { getScreenHeight } from "../../utils/utils.ui";
import { StackProps, VStack } from "../Stack";
import { ScrollView } from "../View";

export const ModalBody = (props: StackProps & { scrollEnabled?: boolean }) => {
  const { components } = useAppTheme();
  const maxHeight = getScreenHeight() - 72 - 64 - 100;
  const style = [
    {
      flexGrow: 1,
    },
    isWeb && {
      maxHeight,
    },
    !props.scrollEnabled && {
      maxHeight,
    },
  ];

  if (props.scrollEnabled) {
    return (
      <ScrollView contentContainerStyle={style}>
        <VStack {...components.modalBody} {...props} />
      </ScrollView>
    );
  }

  return (
    <VStack style={[props.style, style]} {...components.modalBody} {...props} />
  );
};

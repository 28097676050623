import { getCountryCodeForRegionCode } from "awesome-phonenumber";
import { useState } from "react";
import { Image, Pressable } from "react-native";
import { inputHeight, useAppTheme } from "../../../theme";

import { ButtonInteractionState } from "../../Button/Button.types";
import { DropdownMenuList } from "../../Dropdown/Dropdown.List";
import { DropdownMenu } from "../../Dropdown/Dropdown.Menu";
import { Icon } from "../../Icon/Icon";
import { HStack, VStack } from "../../Stack";
import { Text } from "../../Text";
import { View } from "../../View";
import type { CommonInputProps } from "../Form/FormBuilder/FormBuilder.types";
import { FormInput } from "../FormInputs/FormInput";
import { flagCodes } from "./PhoneInput.Flags";
import { usePhoneInputForm } from "./PhoneInput.hooks";
import { formatPhoneNumber } from "./PhoneInput.utils";

export interface PhoneInputProps extends CommonInputProps<string, "phone"> {}

export const PhoneInput = (formInputProps: PhoneInputProps) => {
  const {
    value = "",
    setValue,
    isValid,

    feedback,
    error,
    // allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
  } = usePhoneInputForm(formInputProps);

  const { components, spacing, palette } = useAppTheme();
  const textInputStyle = components.textInput;
  const [countryCode, setCountryCode] = useState("GB");
  const [showDropdown, setDropdown] = useState(false);
  const dialCode = getCountryCodeForRegionCode(countryCode);
  const imageSize = { w: 20, h: 15 };
  const negativeMargin = -(
    textInputStyle?.paddingLeft ??
    textInputStyle?.padding ??
    0
  );

  const dialCodeValue = `+${String(dialCode)}`;
  const formattedValue1 = formatPhoneNumber(
    dialCodeValue + value.replace(/\D+/g, ""),
    countryCode
  ).formattedText.trim();

  const formattedValue2 = formattedValue1.replace(dialCodeValue, "");
  return (
    <VStack>
      <FormInput
        autoCorrect={false}
        autoCapitalize="none"
        autoComplete="email"
        keyboardType="phone-pad"
        value={formattedValue2}
        error={error}
        disabled={disabled}
        label={label}
        optional={optional}
        readonly={readonly}
        feedback={feedback}
        noFeedback={noFeedback}
        onChangeText={(txt) => {
          setValue(txt);
        }}
        onBlur={() => {
          isValid({ showFeedback: true, onBlur: true });
        }}
        iconStart={
          <HStack
            space="1"
            style={{
              height: Number(textInputStyle?.height ?? inputHeight) - 2,
              marginRight: negativeMargin / 3,
            }}
          >
            <Pressable
              disabled={disabled || readonly}
              onPress={() => {
                setDropdown(true);
              }}
              style={({ hovered }: ButtonInteractionState) => {
                return [
                  textInputStyle
                    ? {
                        margin: negativeMargin,
                      }
                    : undefined,
                  {
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: palette.monochrome.base,
                    paddingLeft: spacing[3],
                    paddingRight: spacing[2],
                    height: "100%",
                  },
                  hovered && {
                    backgroundColor: palette.primary.base,
                  },
                ];
              }}
            >
              <HStack space="1">
                <Image
                  style={{
                    width: imageSize.w,
                    height: imageSize.h,
                  }}
                  source={{
                    // Info: https://flagpedia.net/
                    //
                    uri:
                      flagCodes.find((fc) => fc.code === countryCode)?.source ||
                      `https://flagcdn.com/${imageSize.w}x${
                        imageSize.h
                      }/${countryCode.toLocaleLowerCase()}.webp`,
                  }}
                />

                <Icon
                  icon="chevron-down"
                  size="small"
                  color="monochrome-dark"
                />
              </HStack>
            </Pressable>

            <Text.Body2Medium
              style={{ marginLeft: -negativeMargin }}
              color="monochrome-mid"
            >
              {dialCodeValue}
            </Text.Body2Medium>
          </HStack>
        }
      >
        <View style={{ position: "relative" }}>
          <DropdownMenu
            visible={showDropdown}
            modalKey="phoneInput-modal"
            onClose={() => setDropdown(false)}
          >
            <DropdownMenuList
              isSearchable
              value={value}
              data={flagCodes}
              onPress={(flagItem) => {
                setCountryCode(flagItem.code.toLocaleUpperCase());
                setDropdown(false);
              }}
              renderItem={(item) => {
                return (
                  <HStack space="3">
                    <Image
                      style={{
                        width: imageSize.w,
                        height: imageSize.h,
                      }}
                      source={{
                        // Info: https://flagpedia.net/
                        uri: item.source,
                      }}
                    />
                    <Text.Body2Regular>
                      {item.country.length > 15
                        ? item.country.slice(0, 15) + "..."
                        : item.country}{" "}
                      ({item.dialCode})
                    </Text.Body2Regular>
                  </HStack>
                );
              }}
            />
          </DropdownMenu>
        </View>
      </FormInput>
    </VStack>
  );
};

import { Pressable } from "react-native";
import { useAppTheme } from "../../../theme";
import { Icon } from "../../Icon/Icon";
import { HStack } from "../../Stack";
import { Text } from "../../Text";

export const SelectItem = ({
  children,
  onPress,
  single,
}: {
  children: string;
  onPress?: () => void;
  single?: boolean;
}) => {
  const { spacing } = useAppTheme();
  const themeStyle = useAppTheme().components.multiSelectItem;

  if (single) {
    return <Text.Body2Regular>{children}</Text.Body2Regular>;
  }

  return (
    <Pressable
      onPress={onPress}
      disabled={!onPress}
      style={{
        marginVertical: spacing[1],
      }}
    >
      <HStack style={[themeStyle]} space="1">
        <Text.Body2Medium
          style={{ color: themeStyle?.color }}
          numberOfLines={single ? 1 : undefined}
        >
          {children}
        </Text.Body2Medium>
        {onPress ? (
          <Icon
            icon="close"
            color="primary-mid"
            iconStyle={{ marginRight: -8 }}
          />
        ) : null}
      </HStack>
    </Pressable>
  );
};

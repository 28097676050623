import { translate } from "../../../lang/lang";
import { dayjs } from "../../../utils/utils.date";
import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";

export const useDateForm = (props: FormHookProp<string, "date">) => {
  return useInputForm({
    ...props,
    test: (date) =>
      dayjs(date, props.config.format, true).isValid() &&
      date.length === props.config.format.length,

    errorFeedback: translate("app.inputs.invalidDate", {
      format: props.config.format,
    }),
  });
};

import { SelectedValueItem } from "@droplet_tech/core-common-types";
import { useState } from "react";
import { Pressable } from "react-native";
import { inputHeight, useAppTheme } from "../../../theme";
import { isNativeMobile } from "../../../utils/utils.helper";
import { Button } from "../../Button";
import { DropdownMenuList } from "../../Dropdown/Dropdown.List";
import { DropdownMenu } from "../../Dropdown/Dropdown.Menu";
import { Icon } from "../../Icon/Icon";
import { HStack } from "../../Stack";
import { Text } from "../../Text";
import { View } from "../../View";
import { BaseInputWrapper } from "../BaseInputs/BaseInput.Wrapper";
import { FormInputContainer } from "../FormInputs/FormInput.Container";
import { SelectItem } from "./SelectInput.Item";

export interface SelectInputProps {
  options: SelectedValueItem[];
  label?: string;
  readonly?: boolean;
  disabled?: boolean;
  allowEmpty?: boolean;
  noFeedback?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  optional?: boolean;
}

export const SelectInputBase = ({
  label,
  options,
  type,
  readonly,
  disabled,
  values = [],
  feedback,
  setValues,
  allowEmpty,
  noFeedback,
  isClearable,
  isSearchable: propSearchable,
  optional,
}: SelectInputProps & {
  type: "single-select" | "multi-select";
  values: string[] | undefined;
  setValues: (nv: string[] | undefined) => void;
  feedback: string;
}) => {
  const [showDropdown, setDropdown] = useState(false);
  const { components } = useAppTheme();
  const textInputStyle = components.textInput;
  const error = !!feedback;
  const itemHeight = Number(components.buttonInput?.height || inputHeight);
  const isSearchable = propSearchable && options.length > 5;
  return (
    <Pressable
      disabled={disabled || readonly || options.length === 0}
      onPress={() => {
        setDropdown(true);
      }}
    >
      <FormInputContainer
        noFeedback={noFeedback}
        label={label}
        disabled={disabled || options.length === 0}
        error={error}
        feedback={feedback}
        optional={optional}
        readonly={readonly}
      >
        <BaseInputWrapper
          iconEnd="chevron-down"
          error={error}
          inputContainerStyle={{
            flex: 1,
            minHeight: textInputStyle?.height,
            height: undefined,
          }}
        >
          <HStack
            space="1"
            style={[
              type !== "single-select" && {
                flexWrap: "wrap",
              },
            ]}
            w={250}
            flex={1}
          >
            {values.map((value) => {
              return (
                <SelectItem
                  single={type === "single-select"}
                  key={value}
                  onPress={() => {
                    if (values.some((v) => v === value)) {
                      const newItemsAfterRemoving = values.filter(
                        (v) => v !== value
                      );
                      if (newItemsAfterRemoving.length === 0) {
                        return setValues(allowEmpty ? [] : undefined);
                      }
                      return setValues(newItemsAfterRemoving);
                    }
                  }}
                >
                  {options.find((option) => option.value === value)?.label ||
                    value}
                </SelectItem>
              );
            })}
          </HStack>
        </BaseInputWrapper>

        <View style={{ position: "relative" }}>
          <DropdownMenu
            visible={showDropdown}
            modalKey="select-input-dropdown"
            onClose={() => setDropdown(false)}
            height={Math.min(
              options.length * itemHeight +
                options.length +
                1 +
                (isSearchable ? 44 : 0),
              420
            )}
          >
            <DropdownMenuList
              isSearchable={isSearchable}
              isClearable={isClearable}
              value={values}
              onPress={(item) => {
                const newValue = item.value;

                if (type === "single-select") {
                  if (values[0] === newValue) {
                    setDropdown(false);
                    return;
                  }

                  setValues([newValue]);
                  setDropdown(false);
                  return;
                }

                if (values.some((v) => v === newValue)) {
                  setValues(values.filter((v) => v !== newValue));
                  return;
                }

                setValues([...values, newValue]);
                return;
              }}
              data={options}
              renderItem={(item) => {
                const isSelected = !!values.find(
                  (value) => value === item.value
                );

                return (
                  <HStack space="3" flex={1}>
                    <Text.Body2Regular
                      color={isSelected ? "primary-mid" : undefined}
                      style={{ flex: 1 }}
                    >
                      {item.label || item.value}
                    </Text.Body2Regular>
                    {type === "multi-select" ? (
                      isSelected ? (
                        <Icon icon="check" color="primary-mid" />
                      ) : null
                    ) : null}
                  </HStack>
                );
              }}
            />
            {isNativeMobile ? (
              <Button
                onPress={() => {
                  setDropdown(false);
                }}
                style={{
                  borderRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                text="app.inputs.done"
              />
            ) : null}
          </DropdownMenu>
        </View>
      </FormInputContainer>
    </Pressable>
  );
};

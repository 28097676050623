import i18next, { TOptions } from "i18next";

import type { NestedPaths } from "../types/nestedPaths";
import en from "./locales/en";

class i18nManagerClass {
  initiate = async () => {
    await i18next
      .init({
        compatibilityJSON: "v3",
        lng: "en", // if you're using a language detector, do not define the lng option
        debug: false,
        fallbackLng: "en",
        resources: {
          en: {
            translation: en,
          },
        },
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  loadTranslation = () => {
    i18next.addResourceBundle("en", "translation", en);
  };

  loadExtraTranslation = (resource: any) => {
    i18next.addResourceBundle("en", "translation", resource, true, true);
  };
}

export const i18nManager = new i18nManagerClass();

export type TKeys = NestedPaths<typeof en>;
export type TranslatableText = TKeys;
export const t = (key: TKeys, options: TOptions = {}): string => {
  return i18next.t(key, { interpolation: { escapeValue: false }, ...options });
};

export const translate = t;
